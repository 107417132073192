<template>
  <div style="margin-top: 20px">
    <div class="row" style="max-width: 99vw">
      <div class="col-md-2 mb-3">
        <div
          class="card"
          style="height: 100%; overflow-y: auto; overflow-x: clip"
        >
          <div class="card-header">My Sensors</div>
          <div class="card-block">
            <mv-tree-list :source="listType" ref="gatewayTreeList" />
          </div>
        </div>
      </div>
      <div class="col-md-2 mb-3">
        <div
          class="card"
          style="height: 100%; overflow-y: auto; overflow-x: clip"
        >
          <div class="card-header">Overview</div>
          <div class="card-block">
            <OverviewList />
          </div>
        </div>
      </div>
      <div class="col-md-8 mb-3">
        <ul class="nav nav-tabs px-0" id="myTab" role="tablist">
          <li>
            <button
              class="btn btn-primary btn-sm"
              style="margin: 10px"
              @click="refresh">
              <font-awesome-icon icon="sync"/>
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#sensor_chart"
              type="button"
              role="tab"
              aria-controls="sensor_chart"
              aria-selected="true"
            >
              Chart
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#sensor_attributes"
              type="button"
              role="tab"
              aria-controls="sensor_attributes"
              aria-selected="false"
            >
              Attributes
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="contact-tab"
              data-bs-toggle="tab"
              data-bs-target="#sensor_info"
              type="button"
              role="tab"
              aria-controls="sensor_info"
              aria-selected="false"
              v-if="user_access_sensor_info=='true'"
            >
              Sensor Info
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="contact-tab"
              data-bs-toggle="tab"
              data-bs-target="#gateway_info"
              type="button"
              role="tab"
              aria-controls="gateway_info"
              aria-selected="false"
            >
              Gateway Control
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="contact-tab"
              data-bs-toggle="tab"
              data-bs-target="#gateway_location"
              type="button"
              role="tab"
              aria-controls="gateway_location"
              aria-selected="false"
              v-if="user_access_gateway_location=='true'"
            >
              Gateway Location
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="contact-tab"
              data-bs-toggle="tab"
              data-bs-target="#alarm_info"
              type="button"
              role="tab"
              aria-controls="alarm_info"
              aria-selected="false"
            >
              Alarms
              <span
                v-if="alarm_count_active > 0"
                class="badge badge-pill badge-danger blink_me"
                >{{ alarm_count_active }}
              </span>
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="contact-tab"
              data-bs-toggle="tab"
              data-bs-target="#HP_info"
              type="button"
              role="tab"
              aria-controls="HP_info"
              aria-selected="false"
            >
              Holding Parameters
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="contact-tab"
              data-bs-toggle="tab"
              data-bs-target="#queued_info"
              type="button"
              role="tab"
              aria-controls="queued_info"
              aria-selected="false"
            >
              Message Center
              <span
                v-if="pending_message_count > 0"
                class="badge badge-pill badge-yellow"
                >{{ pending_message_count }}
              </span>
            </button>
          </li>
        </ul>
        <div :class="[isLoading ? '' : 'd-none']" class="d-flex align-items-center justify-content-center" style="height: 100%;">
          <div class="spinner-border"/>
        </div>

        <div :class="[isLoading ? 'd-none' : '']" class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="sensor_chart"
            role="tabpanel"
            aria-labelledby="chart-tab"
          >
            <div class="card">
              <div class="card-header">
                <span style="color: red" v-if="has_unresponsive_data"
                  >Warning: unresponsive sensor data is contained in the selected
                  period. Click
                  <a
                    style="text-decoration: underline; cursor: pointer"
                    @click="showModal()"
                  >
                    here
                  </a>
                  to see the data.</span
                >
              </div>
              <div class="card-block">
                <ApgChart
                  listType="group_gateway_sensor"
                />
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="sensor_attributes"
            role="tabpanel"
            aria-labelledby="attribute-tab"
          >
            <gateway-attribute-list-user usage="list"/>
            <SensorAttributeList usage="list" />
            <RegisterAttributeList usage="list" />
          </div>
          <div
            class="tab-pane fade"
            id="sensor_info"
            role="tabpanel"
            aria-labelledby="info-tab"
          >
            <div class="table-responsive">
            <table class="table table-bordered">
              <tr>
                <th>Attribute name</th>
                <th>Attribute value</th>
              </tr>
              <tr v-for="(myVal, myKey) in sensorInfo" :key="myKey">
                <td>{{ myKey }}</td>
                <td style="text-align: right">{{ myVal }}</td>
              </tr>
            </table>
            </div>
          </div>

          <div
            class="tab-pane"
            id="gateway_location"
            role="tabpanel"
            aria-labelledby="info-tab"
          >
            <gateway-map />
          </div>

          <div
            class="tab-pane fade"
            id="gateway_info"
            role="tabpanel"
            aria-labelledby="info-tab"
          >
            <gatewayReadingControl />
          </div>

          <div
            class="tab-pane fade overflow-visible"
            id="alarm_info"
            role="tabpanel"
            aria-labelledby="info-tab"
          >
            <alarm-list />
            <alarm-history />
          </div>
          <div
            class="tab-pane fade"
            id="HP_info"
            role="tabpanel"
            aria-labelledby="info-tab"
          >
            <SensorHoldingRegisters />
          </div>
          <div
            class="tab-pane fade overflow-visible"
            id="queued_info"
            role="tabpanel"
            aria-labelledby="info-tab"
          >
            <gatewayMessage />
          </div>
        </div>
      </div>
    </div>
    <BasicModal title="Choose groups to add" source="user_group"> </BasicModal>
  </div>
</template>

<script>
import {ref} from "vue";
import MvTreeList from "@/components/shared/lists/MV-tree-list";
import OverviewList from "../shared/lists/overview-list.vue";
import ApgChart from "@/components/shared/charts/ApgChart";
import GatewayReadingControl from "@/components/shared/lists/gatewayControlReading-list";
import BasicModal from "../shared/modals/basic-modal";
import { useStore } from "vuex";
import { onMounted, computed } from "vue";
import SensorAttributeList from "../shared/lists/sensor-attribute-list";
import RegisterAttributeList from "../shared/lists/register-attribute-list";
import AlarmList from "@/components/shared/lists/alarm-list";
import AlarmHistory from "@/components/shared/lists/alarm-history";
import gatewayMessage from "@/components/shared/lists/gateway_message";
import SensorHoldingRegisters from "@/components/shared/lists/sensor-holdingRegisters-list";
import GatewayMap from "@/components/shared/charts/GatewayMapMyView";
import GatewayAttributeListUser from "@/components/shared/lists/gateway-attribute-list-user.vue";
import socketInstance from "@/services/Socket";

export default {
  components: {
    GatewayAttributeListUser,
    MvTreeList,
    OverviewList,
    ApgChart,
    GatewayReadingControl,
    BasicModal,
    SensorAttributeList,
    AlarmList,
    AlarmHistory,
    SensorHoldingRegisters,
    gatewayMessage,
    GatewayMap,
    RegisterAttributeList,
  },
  setup() {
    const store = useStore();
    const listType = "group_gateway_sensor";

    const showModal = () => {
      store.commit("chart/showChartModal", { show_modal: true });
    };

    let gatewayTreeList = ref(null);

    // Add new sensor data to the chart
    socketInstance.registerListener("refresh", () => {
      console.log('Received new sensor data');
      setTimeout(() => {
        refresh();
      }, 5000);
    });

    const refresh = async () => {
      console.log('Refreshing data');
      store.commit('settings/setIsLoading', true);
      let sensor_id = store.state.apgList.ids.sensor?.sensor_id;
      let chart_loaded = store.state.chart.isLoaded?.group_gateway_sensor;
      if (sensor_id && chart_loaded) {
        await store.dispatch('sensor/findInfo', {
          id: sensor_id,
          listType: listType,
          chart: true
        });
        await store.dispatch('sensor/findInfoOverview', {id: sensor_id});
        await store.dispatch('sensor/getRegisters', {sensor_id: sensor_id});
      }
      store.commit('settings/setIsLoading', false);
    };

    onMounted(() => {
      store.dispatch("group/findAllForMe", { listType });
    });

    return {
      listType,
      sensorInfo: computed(() => store.state.apgList.List["sensor_info"]),
      readingsInfo: computed(() => store.state.sensor.readings),
      minValue: computed(() => store.state.sensor.min_value),
      maxValue: computed(() => store.state.sensor.max_value),
      alarm_count_active: computed(() => store.state.apgList.List["alarm_count_active"]),
      pending_message_count: computed(() => store.state.apgList.List["gateway_queued_message"].length),
      messageQueue: computed(() => store.state.apgList.List["gateway_queued_message"]),
      has_unresponsive_data: computed(() => store.state.chart.has_unresponsive_data["group_gateway_sensor"]),
      user_access_gateway_location: computed (() => store.state.auth.user?.user?.Info?.accessToGPSLocation),
      user_access_sensor_info: computed (() => store.state.auth.user?.user?.Info?.myViewSensorInfoView),
      user_access_holding_params: computed (() => store.state.auth.user?.user?.Info?.extendedHoldingParameters),
      isLoading: computed(() => store.state.settings.is_loading),
      showModal,
      gatewayTreeList,
      refresh,
    };
  },
};
</script>

<style scoped>
table {
  margin: 20px;
  width: 400px;
}
</style>
