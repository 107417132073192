import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faPlus,
  faWindowClose,
  faEraser,
  faSatellite,
  faEye,
  faUserSecret, 
  faCheck,
  faEdit,
  faBell, 
  faEnvelope,
  faMoon,
  faSun,
  faGhost,
  faScrewdriver,
  faDollarSign,
  faHatWizard,
  faNetworkWired,
  faSync,
  faMicrochip
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faPlus,
  faWindowClose,
  faEraser,
  faSatellite,
  faEye,
  faUserSecret,
  faCheck,
  faEdit,
  faBell,
  faEnvelope,
  faMoon,
  faSun,
  faGhost,
  faScrewdriver,
  faDollarSign,
  faHatWizard,
  faNetworkWired,
  faSync,
  faMicrochip
);

export { FontAwesomeIcon };
