<template>
  <div class="row">
    <div class="col-md-4">
      <label> {{ name }} </label>
    </div>
    <div class="col-md-8">
      <input class="form-control-sm"
             type="email"
             :id="id"
             :disabled="disabled"
             :value="value"
             @change="change(attId, attInfoId, ownerId, listType, $event.target.value)"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
  export default {
    name: "AttributeEmail",
    components: {},
    props: {
      id: String,
      value: String,
      name: String,
      attId: Number,
      disabled: Boolean,
      attInfoId:Number,
      ownerId: Number,
      listType: String
    },
    setup() {
      const store = useStore();
      const change = function (id, attInfoId, ownerId, listType, value) {
        console.log('index is ', id)
        console.log('value is ', value)
        if (id != 0) {
          store.dispatch("user/updateAttribute", {value:value, id:id, listType}).then(() => {
          console.log('changed attribute complete')
          });
        } else {
          store.dispatch("user/insertAttribute", {value:value, id:attInfoId, ownerId, listType}).then(() => {
          console.log('changed attribute complete')
          });
        }
        
      }
      return{
        change,
      }
    },
  };
</script>

<style scoped>
  input {
    width:100%;
  }
</style>