<template>
  <div class="row">
    <div class="col-md-4">
      <label> {{ name }} </label>
    </div>
    <div class="col-md-8">
      <apg-dropdown
        :valueField="valueField ?? table + '_id'"
        :table="table"
        :descriptionField="descriptionField ?? table.substring(0,table.indexOf('_',table)) + '_name'"
        :showDefaultValue="showDefaultValue ?? true"
        defaultEmptyValue="0"
        :defaultEmptyValueMessage="defaultEmptyValueMessage ?? 'Please choose value'"
        idx="0"
        :listType="listType"
        type="Regular"
        @DropdownChanged="processDropdown"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
import ApgDropdown from '@/components/shared/lists/apg-dropdown'
import {useStore} from 'vuex'

export default {
  name: 'AttributeDropdown',
  components: {ApgDropdown},
  props: {
    id: String,
    value: String,
    name: String,
    attId: Number,
    disabled: Boolean,
    attInfoId: Number,
    ownerId: Number,
    listType: String,
    table: String,
    AutoSave: Boolean,
    valueField: String,
    descriptionField: String,
    showDefaultValue: Boolean,
    defaultEmptyValueMessage: String,
  },
  setup(props) {
    const store = useStore();

    // make sure if we already have a value that it is selected
    store.commit('apgList/setDropdownValue',  {
      idx:0,
      table:props.table,
      listType: props.listType,
      data: props.value
    });

    function processDropdown(val) {
      console.log('Child component ApgDropdown emitted event DropdownChanged: ' + val)
      // val is zero when we're on the selection message
      if (props.attId == 0 && val != 0) {  // insert attribute
        store.dispatch('apgList/insertAttributeValue', {
          attribute_info_id:props.attInfoId,
          attribute_value:val,
          ownerId:props.ownerId,
        });
      } else {  // update attribute
        store.dispatch('apgList/updateAttributeValue', {attribute_id: props.attId, attribute_value: val});
      }
    }

    return {
      processDropdown
    }
  },
}
</script>
