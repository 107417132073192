export const MyView = {
    namespaced: true,
  
    state: {
        data: {
            group_gateway_sensor:[{id:0,label: "no data found"},],
        },
        user_id: 0,
        searchText: "",
        treeRef: null
    },
  
    mutations: {
        addParents(state, payload) {
            payload.data.List.forEach(function(item,idx){
                // console.log('in add parents - item is ',item)
                payload.data.List[idx] = {...item,idx,nodes:[{id:0,label:'no data found',level:'group'}]}
            })
            state.data[payload.parent] = payload.data.List
        },
        addChildrenToParent(state, payload) {
            payload.data.List.forEach(function(item,idx){
                payload.data.List[idx] = {...item,idx2:idx, idx:payload.idx, groupIndex:payload.idx,gatewayIndex:idx,nodes:[{id:0,label:'no data found',level:'gateway'}]}
            })      
            state.data[payload.listType][payload.idx]['nodes'] = payload.data.List
        },
        addChildrenToChild(state, payload) {
            payload.data.List.forEach(function(item,idx){
                payload.data.List[idx] = {...item,idx2:idx, idx:payload.idx,sensorIndex:idx,gatewayIndex:payload.idx2,groupIndex:payload.idx}
            })
            state.data[payload.listType][payload.idx]['nodes'][payload.idx2]['nodes'] = payload.data.List
        },
        setSearchText(state, payload) {
            state.searchText = payload.text
        },
        setTreeRef(state,payload) {
            state.treeRef = payload
        }
    },

    getters: {
        childList: (state) => (id) => {
            return state.data['group_gateway'][id].children
        },
        parentList: (state) => (id) => {
            return state.data[id]
        },
        findGroupId: (state) => (parent,idx) => {
            console.log('parent and idx are: ',parent,idx) 
            return state.data[parent][idx].system_group_id 
        },
        getChildKey: () => (parent,parentidx,childidx) => {
            console.log('parent and idx are: ',parentidx,childidx) 
            return parent + '_' + parentidx + '_' + childidx
        },
        childChildList: (state) => (parentid,childid) => {
            return state.data['group_gateway'][parentid].children[childid].children
        },
    }
}