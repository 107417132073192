<template>
    <div
      v-if="registrationVerified && !verificationError"
      class="mt-4 p-5 bg-success text-white rounded text-center"
    >
      <div>
        <div>Your registration was successfully verified.</div>
        <div class="p-5">
          Please click
          <router-link to="/login" 
            style='color:white;font-weight:bold;text-decoration:underline;margin-left:5px;margin-right:5px;font-size:14pt'>here</router-link>
          to continue to log in to the Nomad Explorer application
        </div>
      </div>
      </div>

    <div v-if="!registrationVerified && !verificationError" class="mt-4 p-5 bg-secondary text-white rounded text-center">
      Please wait , your registration is being verified.
    </div>

    <div v-if="verificationError" class="mt-4 p-5 bg-danger text-white rounded text-center" >
      There was an error validating your registration. Please click the link in
      your email again. If you still receive this error please contact the Nomad
      Explorer sales team.
    </div>
</template>

<script>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export default {
  name: 'VerifyRegister',
  setup() {
    const store = useStore()
    const route = useRoute()

    onMounted(() => {
      store.dispatch('auth/verifyRegister', route.params.uuid)
    })

    return {
      registrationVerified: computed(() => store.state.auth.verifyRegister),
      verificationError: computed(() => store.state.auth.verifyRegisterError),
    }
  },
}
</script>
