<template>
  <div class="row">
    <div class="col-md-4">
      <label> {{ name }} </label>
    </div>
    <div class="col-md-8">
      <input class="form-control-sm" type="number" :min="min" :max="max" :id="id" :disabled="disabled" :value="value" @input="change(attId, attInfoId, ownerId, listType, $event.target.value, min,max)"/>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
  export default {
    name: "AttributeNumber",
    components: {},
    props: {
      id: Number,
      value: Number,
      name: String,
      attId: Number,
      disabled: Boolean,
      attInfoId:Number,
      ownerId: Number,
      listType: String,
      min: Number, 
      max: Number
    },
    setup() {
      const store = useStore();
      const change = function (id, attInfoId, ownerId, listType, value, min, max) {
        console.log('index is ', id)
        console.log('value is ', value)
        if (value >= min && value <= max) {
          if (id != 0) {
            store.dispatch("user/updateAttribute", {value:value, id:id, listType}).then(() => {
            console.log('changed attribute complete')
            });
          } else {
            store.dispatch("user/insertAttribute", {value:value, id:attInfoId, ownerId, listType}).then(() => {
            console.log('changed attribute complete')
            });
          }
        } else {
          alert ('The value for this attribute must be greater than ' + min + ' and lower than ' + max)
        }
        
      }
      return{
        change,
      }
    },
  };
</script>

<style scoped>
  input {
    width:100%;
  }
</style>