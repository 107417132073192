<template>
    <div>
        <div style="text-decoration:underline;cursor: pointer" @click='downloadCSVData'>Export data</div>
    </div>
</template>

<script>
import {useStore} from 'vuex'

export default {
    props: {
        dataSourceKey: String,
        outputFileName: String,
    },    
    setup(props) {
        let store = useStore()

        function downloadCSVData() {
            let data = store.state.apgList.exportData[props.dataSourceKey]
            let csv = Object.keys(data[0]).join(",") + "\n"
            store.state.apgList.exportData[props.dataSourceKey].forEach((row) => {
                csv += Object.values(row).join(",") + "\n"
            });

            const dt = new Date()
            const curr_date = 
                dt.getFullYear() + "-"
                +(((dt.getMonth()+1) < 10)?"0":"") + (dt.getMonth()+1) + "-"
                + ((dt.getDate() < 10)?"0":"") + dt.getDate() 
            
            const anchor = document.createElement('a')
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
            anchor.target = '_blank'
            anchor.download = curr_date + "__" + props.dataSourceKey.replace("_","") + "__" + props.outputFileName
            anchor.click()
        }

        return {
            downloadCSVData
        }
    }
}

</script>