<template>
  <D3LineChart v-if="isLoaded && chartType == 'line'" :data="sensorData" :ai="aiData"/>
  <D3BarChart v-if="isLoaded && chartType == 'bar'" :data="sensorData" :ai="aiData"/>
  <D3WindChart v-if="isLoaded && chartType == 'wind'" :data="sensorData" :ai="aiData"/>
  <div class='row justify-content-center'>
    <div class='col-md-8'>
      <div class="d-flex flex-row" v-if="isLoaded">
        <div class='p-1'>
          <span style='font-weight:bold'>Chart type</span>
        </div>
        <div class="p-1">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="chartType" id="flexRadioDefault1" v-model="chartType"
                   value="line" />
            <label class="form-check-label" for="flexRadioDefault1"> Line </label>
          </div>
        </div>
        <div class="p-1" v-if="sensorModel == 13">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="chartType" id="flexRadioDefault2" v-model="chartType"
                   value="wind" />
            <label class="form-check-label" for="flexRadioDefault2"> Wind </label>
          </div>
        </div>
        <div class="p-1">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="chartType" id="flexRadioDefault3" v-model="chartType"
              value="bar" />
            <label class="form-check-label" for="flexRadioDefault3"> Bar </label>
          </div>
        </div>
        <div class='p-1'> | </div>
        <div class='p-1'>
          <export-data columnHeaders="" dataSourceKey="sensor_readings" outputFileName="ChartReadings.csv" />
        </div>
        <div class="p-1"> | </div>
        <div class="p-1">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="aiSwitch" @change="aiToggle" :disabled="!aiEnabled"/>
            <label class="form-check-label" for="aiSwitch">AI Predictions</label>
          </div>
        </div>
        <div class="p-1"> | </div>
        <div class="p-1">
          <div
              style="text-decoration:underline;cursor: pointer"
              :style="{ opacity: aiEnabled ? 1 : 0.5, cursor: aiEnabled ? 'pointer' : 'default' }"
              @click="aiEnabled && removeOutliers()">
            Remove Outliers
          </div>
        </div>
      </div>
    </div>
  </div>

  <chart-filter :listType="listType" />
  <unresponsive-data-modal />
</template>

<script>
import {computed} from "vue";
import {useStore} from "vuex";
import ChartFilter from './chartFilters.vue'
import ExportData from '../Export/ExportData.vue'
import UnresponsiveDataModal from '../modals/chart-unresponsive-modal'
import D3LineChart from "@/components/shared/charts/D3LineChart.vue";
import D3BarChart from "@/components/shared/charts/D3BarChart.vue";
import D3WindChart from "@/components/shared/charts/D3WindChart.vue";

export default {
  name: "ApgBarChart",
  components: {
    D3BarChart,
    D3LineChart,
    D3WindChart,
    ChartFilter,
    UnresponsiveDataModal,
    ExportData,
  },
  props: {
    listType: String,
  },
  setup(props) {
    const store = useStore();
    const chartFields = ['sensor_id', 'address', 'sensor_data_value', 'receive_datetime', 'sensor_address'];

    const chartType = computed({
      get() {
        return store.state.chart.type[props.listType]
      },
      set(value) {
        store.dispatch("chart/setChartType", { listType: props.listType, chartType: value }, { root: true });
      }
    });

    const aiToggle = (e) => {
      store.commit("chart/setShowingPredictions", e.target.checked, { root: true });
    };

    const removeOutliers = () => {
      store.dispatch("chart/removeOutliers", {
        listType: props.listType,
        id: store.state.apgList.List.sensor_info.sensor_id,
        address: store.state.apgList.List.sensor_overview.find((item) => item.attribute_name === 'addressToRead')?.attribute_value,
      }, { root: true });
    };

    const aiEnabled = computed(() => {
      let regAttrs = store.state.apgList.List.register_attribute;
      if (!regAttrs) return false;
      return regAttrs.find((item) => item?.attribute_name === 'predictions_enabled')?.attribute_value === 'true';
    });

    return {
      regToRead: computed(() => store.state.chart.register_to_read?.toString()),
      sensorData: computed(() => store.state.chart.data[props.listType]),
      aiData: computed(() => store.state.chart.data['ai_predictions']),
      rawData: computed(() => store.state.chart.rawData[props.listType]),
      isLoaded: computed(() => store.state.chart.isLoaded[props.listType]),
      showAi: computed(() => store.state.chart.showing_predictions),
      sensorModel: computed(() => store.state.apgList.List.sensor_attribute.find((item) =>
          item.attribute_name === 'SensorModel')?.attribute_value),
      chartType,
      chartFields,
      aiToggle,
      aiEnabled,
      removeOutliers,
    }
  }
}
</script>
<style>
canvas {
  min-width: 100%;
  min-height: 100%;
}
</style>
