import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/pages/Home.vue";
import Login from "./components/pages/Login.vue";
import Register from "./components/pages/Register.vue";
import Gateways from "./components/pages/Gateways.vue"
import ForgotPassword from "./components/pages/ForgotPassword.vue"
import VerifyRegister from './components/pages/VerifyRegister.vue'
import ChangePassword from './components/pages/ChangePassword.vue'
import AuditDashboard from './components/pages/AuditDashboard.vue'
import LockEmail from "@/components/pages/LockEmail.vue";
import store from './store/index.js'
import routeAccessService from "@/services/routeAccess.service";

// lazy-loaded
const Profile = () => import("./components/pages/Profile.vue")
const BoardAdmin = () => import("./components/pages/BoardAdmin.vue")

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
  },
  {
    path: "/ForgotPassword",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: '/user/verify/:uuid',
    component: VerifyRegister,
  },
  {
    path: '/user/lockEmail/:email',
    component: LockEmail,
  },
  {
    path: '/user/forgotpassword/:uuid',
    component: ChangePassword,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },
  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: BoardAdmin,
  },
  {
    path: "/auditDB",
    name: "Audit dashboard",
    // lazy-loaded
    component: AuditDashboard,
  },  
  {
    path: "/gateways",
    name: "gateways",
    component: Gateways,
  }, 
//  {
//    path: "/verifyRegistration",
//    name: "gateways",
//    component: Gateways,
//    props: (route) => ({ name: route.query.name })
//  }

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// make sure user is logged in for certain routes
router.beforeEach((to, from, next) => {
  // /user/verify/
  const publicPages = ['/login', '/register', '/ForgotPassword'];
  //const adminPages = ['/admin'];
  const authRequired = (to.path.substring(0, 12) == '/user/verify' || to.path.substring(0, 20) == '/user/forgotPassword')
    ? false
    : !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in = redirect to login page
  console.log("router.beforeEach %s", JSON.stringify(loggedIn));
  console.log('to', to)
  if (authRequired && !loggedIn) {
    localStorage.removeItem('user');
    console.log("NOT LOGGED in , re-routing to /login");
    next('/login');
  } else if (!authRequired) {
    // if they're trying to get to a public page, just let them through
    next();
  } else {
    store.dispatch('auth/refreshCurrentUserAttributes', to.path)
      .then(() => {
        if (to.fullPath == '/admin' || to.fullPath == "/auditDB") {
          // user type routing (below) will handle this
          next();
        }
        console.log("logged in OK");
        next();
      })
      .catch(e => {
        console.log('user auth info refresh error: ', e);
        next();
      });
  }
});

// user type based routing
router.beforeEach((to, from, next) => {
  const user = JSON.parse(localStorage.getItem('user'));
  let user_type = user?.user?.Info?.user_type;
  console.log('user_type', user_type);

  // don't need to guard these routes
  let publicPages = ['/login', '/register', '/ForgotPassword'];
  if (publicPages.includes(to.path)) {
    next();
    return;
  } else if (to.path.substring(0, 12) === '/user/verify' || to.path.substring(0, 20) == '/user/forgotPassword') {
    next();
    return;
  }

  // only allow certain routes based on user type
  let allowedPages = routeAccessService(user_type);
  if (allowedPages.includes(to.path)) {
    next();
  } else {
    if (allowedPages.includes(from.path)) {
      next(from.path);
    } else {
      next('/login');
    }
  }
});

export default router;
