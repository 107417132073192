<template>
  <div class="card overflow-visible">
    <div class="card-header">
      Alarm
      <div style="float: right">
        <button @click="addAlarm" class="btn btn-outline-primary">
          <font-awesome-icon icon="plus" style="font-size: 11pt" />
          Add Alarm
        </button>
        <button
            @click="saveAlarms"
            class="btn btn-outline-primary"
            id="saveBtn"
        >
          <font-awesome-icon icon="satellite" style="font-size: 11pt" />
          Save & Update Queue
        </button>
      </div>
    </div>
    <div class="card-block table-responsive-md">
      <table class="table table-bordered text-center text-nowrap">
        <tr>
          <th>Sensor ID</th>
          <th>Register</th>
          <th>Alarm Type</th>
          <th>Alarm Value</th>
          <th>Notifications</th>
          <th>Action</th>
        </tr>

        <AlarmItem
          :idx="a.alarm_id"
          :alarm_register="a.alarm_register"
          :alarm_type="a.alarm_type_id"
          :hysteresis="a.alarm_set_point_2"
          :status="a.status"
          :sensor_id="a.sensor_id"
          :alarm_value="a.alarm_set_point"
          :array_idx="a_index"
          :toWhom="a.toWhom"
          v-bind:key="a.alarm_id"
          v-for="(a, a_index) in alarm_set"
        />
      </table>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import AlarmItem from './alarm-item';

export default {
  components: {
    AlarmItem,
  },
  setup() {
    const store = useStore()

    let sensor_id = computed(() => {
      return store.state.alarm.current_sensor_id
    });

    let saveAlarms = function() {
      store.dispatch('alarm/SaveAlarms');
    }

    function addAlarm() {
      if (sensor_id.value == '') {
        alert('Please select a sensor first');
        return;
      }

      store.dispatch('alarm/AddNewAlarm');
    }

    return {
      alarm_set: computed(() => store.state.apgList.List['alarm_set']),
      sensor_id,
      saveAlarms,
      addAlarm,
    }
  },
}
</script>

<style>
.pulse {
    animation: pulse 2s 2
}

  @keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 var(--color-border);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px var(--color-border);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 var(--color-border);
	}
}
</style>
