import axios from 'axios'
import { useToast } from 'vue-toastification';

const toast = useToast();

export const alarm = {
  namespaced: true,
  state: {
    current_sensor_id: 0,
    current_alarm_id: 0,
    current_alarm_value: 0,
    current_alarm_register: 0,
    current_gateway_id: 0,
    alarm_history: [],
    
    url_get_user_sensor : 'alarm/getSensorUsersContact/',
    url_acknowledge_alarm : 'alarm/acknowledge/',
    url_save_alarms: 'alarm/saveAll'
  },
  mutations: {
    setCurrentSensor(state,payload) {
      state.current_sensor_id = payload.id
    },
    setRegister(state,payload) {
      state.current_alarm_register = payload.data
    },
    setValue(state,payload) {
      state.current_alarm_value = payload.data
    },
    setAlarmId(state,payload) 
    {
      state.current_alarm_id = payload.data
    },
    setCurrentGatewayID(state,payload) {
      state.current_gateway_id = payload
    },
  },actions: {
    
    async addAlarm({rootState,commit}, payload) {
      let url = rootState.settings.api_base + 'alarm/add'
      try {
        let rs = await axios.post(url,payload)
        commit('setAlarmId',rs.data)
      } catch(err) {
        console.error('Failed to add alarm',err)
      }
      console.log('adding alarm - parameters are ',payload,url)
    }, 

    async AcknowledgeAlarm ({rootState, commit,state}, payload) {
      let url = rootState.settings.api_base + state.url_acknowledge_alarm + payload.alarm_data_id;
      try {
        let rs = await axios.get(url)
        console.log(rs)
        commit('apgList/setList', { listType:"alarm_history", data: rs.data.AlarmHistory }, {root: true});
        commit('apgList/setList', { listType:"alarm_count_active", data: rs.data.AlarmCountActive }, {root: true});
      } catch (err) {
        console.error('Failed to get reading control function ',err)
      }
    },
    
    async AddNewAlarm ({rootState, state, commit}) {
      let sensor_id = rootState.sensor.id;
      let url = rootState.settings.api_base + state.url_get_user_sensor + sensor_id;
      console.log('url is', url)
      try {
        let rs = await axios.get(url)

        if(!sensor_id || sensor_id == 0) {
          alert('You must choose a sensor before adding a new alarm.')
          return
        }

        let json = {
          "sensor_id":rootState.sensor.id,
          'sensor_type_id': rootState.apgList.List.sensor_info.sensor_type_id,
          'sensor_address': rootState.apgList.List.sensor_info.address,
          'alarm_type_id': '0',
          'alarm_set_point_2': '0',
          "alarm_register":"0",
          "register_type_id": "0",
          "alarm_set_point":"44",
          "toWhom":rs.data,
          "status":"new",
          // Add any other alarm data needed by Nomad here
        }

        commit('apgList/addNew', { listType:'alarm_set', data : json }, {root: true})
        commit('apgList/setDropdownValue',{table:'alarm_action_type',data:5},{root:true})
        commit('apgList/setDropdownValue',{table:'alarm_type',data:0},{root:true})
         
      } catch (err) {
        console.error('Failed to add new alarm, could not find sensor info ',err)
      }
    },
    async removeAlarm ({commit}, payload) {
      commit('apgList/setSingle', { listType:'alarm_set', index: payload.id, name : 'status', data: 'deleted' }, {root: true})
    },
    async SaveAlarms({state,rootState,dispatch}) {
      let url = rootState.settings.api_base + state.url_save_alarms
      let post_data = rootState.apgList.List['alarm_set']
      let alarm_types = rootState.apgList.dropdown_values['alarm_type']
      let action_types = rootState.apgList.dropdown_values['alarm_action_type']
      for(let i=0;i<post_data.length;i++) {
        post_data[i]['alarm_type_id'] = alarm_types[i]
        post_data[i]['alarm_action_type_id'] = action_types[i]
        post_data[i]['gateway_id'] = rootState.apgList.ids.gateway.gateway;
      }
      console.log('url is',url)
      console.log('post data is',{...post_data})

      try {
        await axios.post(url,post_data)
        dispatch('gateway/findAlarmGateway', { id: state.current_gateway_id },{root:true})
        // update message center
        dispatch('gateway/findQueuedMessages', {id: rootState.apgList.ids.gateway.gateway}, {root:true});
        // update overview
        dispatch('gateway/findInfoOverview', {id: rootState.apgList.ids.gateway.gateway}, {root:true});
        toast.success('Queued the alarms message!');
      } catch(err) {
        console.error('SaveAlarms ERROR: data was', post_data);
        console.error('SaveAlarms ERROR: error was', err);
        toast.error('Unable to queue alarms message. Please try again later.');
      }
    },
  },
}