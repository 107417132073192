<template>
  <div ref="resizeRef">
    <svg ref="svgRef" height="400" width="1200">
    </svg>
  </div>
</template>

<script>
import {computed, onBeforeUnmount, onMounted, ref} from 'vue';
import {
  select,
  line,
  scaleTime,
  scaleLinear,
  min,
  max,
  axisBottom,
  axisLeft, brush,
} from 'd3';
import useResizeObserver from '@/components/shared/charts/resizeObserver';
import {useTippy} from 'vue-tippy';
import 'tippy.js/dist/tippy.css';
import {useStore} from "vuex";
import {useMutationObserver} from "@vueuse/core";

export default {
  name: 'D3WindChart',
  props: [
    'data',
    'ai'
  ],
  setup(props) {
    const store = useStore();

    const svgRef = ref(null);
    const { resizeRef, resizeState } = useResizeObserver();

    // chart colors
    const sensorClr = '#F7941D';
    const aiClr = '#1900ff';

    // tooltip element ref
    const ttElem = ref();

    // time format options
    const timeOptions = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };

    // margins/padding
    let paddingLeft = 30;
    const paddingBtm = 50;

    const drawWindChart = (data) => {
      const svg = select(svgRef.value);
      const x = data.map(i => i.x);
      const y = data.map(i => i.y);
      let { width, height } = resizeState.dimensions;

      if (width === 0 || height === 0) {
        let svgRect = svgRef.value.getBoundingClientRect();
        width = svgRect.width;
        height = svgRect.height;
      }

      let units = store.state.apgList.List.register_attribute?.find(i => i.attribute_name === 'register_units')?.attribute_value;
      let name = store.state.apgList.List.register_attribute?.find(i => i.attribute_name === 'register_name')?.attribute_value;
      units = units == 0 ? '' : units;
      name = name == 0 ? '' : name;
      let ylabelBuffer = units || name ? 40 : 20;

      // adjust left according to the number of digits in the max value
      paddingLeft = ylabelBuffer + parseInt(max(y)).toString().length * 5;

      if (width - paddingLeft < 0 || height - paddingBtm < 0) {
        return;
      }

      if (data.length === 0) {
        svg.append('text')
          .text('No Data Found')
          .attr('class', 'no-data')
          .attr('x', width/2 - 75)
          .attr('y', height/2)
        return;
      }

      // scales
      const xScale = scaleTime()
          .domain([min(x), max(x)])
          .range([paddingLeft + 5, width - 5]);

      let mxy = max(y);
      let yRatio = Math.abs(mxy) * .05;
      const yScale = scaleLinear()
          .domain([min(y) - yRatio, max(y) + yRatio])
          .range([height - paddingBtm, 0]);

      // brushing behavior
      svg.call(brush()
          .extent([[paddingLeft, 0], [width, height - paddingBtm]])
          .on('end', ({selection}) => {
            if (selection) {
              const [[x0, y0], [x1, y1]] = selection;
              let values = svg.selectAll('path.arrow')
                  .filter(d => x0 <= xScale(d.x) && xScale(d.x) <= x1 && y0 <= yScale(d.y) && yScale(d.y) <= y1)
                  .data();

              if (values?.length === 0) {
                return;
              }

              cleanupOldChart();
              drawWindChart(values);
              svg.call(brush().clear);
            }
          }));

      // double click to reset chart
      svg.on('dblclick', () => {
        cleanupOldChart();
        drawWindChart(props.data);
      });

      // grid lines
      svg.selectAll('line.grid')
        .data(yScale.ticks())
        .enter()
        .append('line')
        .attr('class', 'grid')
        .attr('x1', paddingLeft)
        .attr('x2', width)
        .attr('y1', d => yScale(d)+0.5)
        .attr('y2', d => yScale(d)+0.5)
        .style('stroke-width', 0.5)

      // axes
      const xAxis = axisBottom(xScale);
      svg.append('g')
        .attr('class', 'xaxis')
        .style('transform', `translateY(${height-paddingBtm}px)`)
        .call(xAxis);

      const yAxis = axisLeft(yScale);
      svg.append('g')
        .attr('class', 'yaxis')
        .style('transform', `translateX(${paddingLeft}px)`)
        .call(yAxis);

      // y axis label
      svg.append('text')
          .attr('class', 'yaxis-label')
          .attr('transform', 'rotate(-90)')
          .attr('y', 0)
          .attr('x', 0 - (height / 2))
          .attr('dy', '1em')
          .style('text-anchor', 'middle')
          .text(`${name} ${units}`);

      // legend
      let legend = svg.append('g').attr('class', 'legend');
      legend.append("rect")
        .attr("x", width/2-100)
        .attr("y",height-25)
        .attr("height", 20)
        .attr('width', 20)
        .style("fill", sensorClr);
      legend.append("text")
        .attr("x", width/2-75)
        .attr("y", height-15)
        .attr("alignment-baseline","middle")
        .text("Sensor Value")
        .style("font-size", "15px")

      let arrowLine = line();
      let arrowDef = [[10, 22], [12, 22], [12, 10], [18, 10], [10, 0], [2, 10], [8, 10], [8, 22], [10, 22]];

      let arrowScale = max([data.length * 0.025, 1.25]);
      arrowDef.forEach(point => {
        point[0] = point[0] / arrowScale;
        point[1] = point[1] / arrowScale;
      });

      svg.selectAll('path.arrow')
          .data(data)
          .join('path')
          .on('mouseenter', (e, d) => {
            ttElem.value = e.target;
            useTippy(ttElem, {
              arrow: true,
              allowHTML: true,
              content: () => `${d.x.toLocaleString([], timeOptions)}<br/>Speed: ${d.y} ${units}<br/>Direction: ${alignment(d.z)}`,
              inlinePositioning: true,
              showOnCreate: true,
            })
          })
          .on('mouseout', e => {
            select(e.target).attr('fill', sensorClr);
            select(e.target).attr('stroke', sensorClr);
          })
          .transition()
          .attr('class', 'arrow')
          .attr('d', arrowLine(arrowDef))
          .attr('transform', d => `translate(${xScale(d.x)}, ${yScale(d.y)}) rotate(${d.z - 200})`)
          .attr('fill', sensorClr)
          .attr('stroke', sensorClr)
    }

    const updateChartAi = (aiData, sensorData) => {
      const svg = select(svgRef.value);
      let {width, height} = resizeState.dimensions;
      if (sensorData.length === 0 || aiData.length === 0) {
        // don't show prediction if there is no sensor data
        return;
      }
      let units = store.state.apgList.List.register_attribute?.find(i => i.attribute_name === 'register_units')?.attribute_value;
      if (units == 0)
        units = '';

      let ax = aiData.map(i => i.x);
      let ay = aiData.map(i => i.y['0.5']);
      let sx = sensorData.map(i => i.x);
      let sy = sensorData.map(i => i.y);

      // update scales
      const xScale = scaleTime()
          .domain([min(sx.concat(ax)), max(sx.concat(ax))])
          .range([paddingLeft + 5, width - 5]);

      let mxy = max(sy.concat(ay));
      let yRatio = mxy * .05;
      const yScale = scaleLinear()
          .domain([min(sy.concat(ay)) - yRatio, max(sy.concat(ay)) + yRatio])
          .range([height - paddingBtm, 10]);

      // update axes
      let xAxis = axisBottom(xScale);
      svg.selectAll('g.xaxis').remove();
      svg.append('g')
          .attr('class', 'xaxis')
          .style('transform', `translateY(${height - paddingBtm}px)`)
          .call(xAxis)

      let yAxis = axisLeft(yScale);
      svg.selectAll('g.yaxis').remove();
      svg.append('g')
          .attr('class', 'yaxis')
          .style('transform', `translateX(${paddingLeft}px)`)
          .call(yAxis);

      // update grid lines
      svg.selectAll('line.grid')
          .data(yScale.ticks())
          .join('line')
          .attr('class', 'grid')
          .attr('x1', paddingLeft)
          .attr('x2', width)
          .attr('y1', d => yScale(d)+0.5)
          .attr('y2', d => yScale(d)+0.5)

      // update legend
      let legend = svg.selectAll('g.legend');
      legend.append("rect")
          .attr("x", width/2+50).attr("y", height-25).attr("height", 20).attr('width', 20)
          .style("fill", aiClr);
      legend.append("text")
          .attr("x", width/2+75).attr("y", height-15).attr("alignment-baseline","middle")
          .text("AI Prediction")
          .style("font-size", "15px");

      // arrow path
      let arrowLine = line();
      let arrowDef = [[10, 22], [12, 22], [12, 10], [18, 10], [10, 0], [2, 10], [8, 10], [8, 22], [10, 22]];

      let arrowScale = max([(sensorData.length + aiData.length) * 0.025, 1.25]);
      arrowDef.forEach(point => {
        point[0] = point[0] / arrowScale;
        point[1] = point[1] / arrowScale;
      })

      // update sensor arrows
      svg.selectAll('path.arrow')
          .join('path')
          .transition()
          .attr('class', 'arrow')
          .attr('transform', d => `translate(${xScale(d.x)}, ${yScale(d.y)}) rotate(${d.z-200})`)

      // ai arrows
      svg.selectAll('path.aiarrow')
          .data(aiData)
          .join('path')
          .on('mouseenter', (e, d) => {
            ttElem.value = e.target;
            useTippy(ttElem, {
              arrow: true,
              allowHTML: true,
              content: () => `${d.x.toLocaleString([], timeOptions)}<br/>Speed: ${d.y['0.5']} ${units}<br/>Direction: ${alignment(d.z['0.5'])}`,
              inlinePositioning: true,
              showOnCreate: true,
            })
          })
          .on('mouseout', e => {
            select(e.target).attr('fill', aiClr);
            select(e.target).attr('stroke', aiClr);
          })
          .transition()
          .attr('class', 'aiarrow')
          .attr('d', arrowLine(arrowDef))
          .attr('transform', d => `translate(${xScale(d.x)}, ${yScale(d.y['0.5'])}) rotate(${d.z['0.5']-200})`)
          .attr('fill', aiClr)
          .attr('stroke', aiClr);
    }

    const cleanupOldChart = () => {
      const svg = select(svgRef.value);
      svg.selectAll('svg > *').remove();
    };

    const onResize = () => {
      cleanupOldChart();
      drawWindChart(props.data);
    };

    const alignment = (deg) => {
      // N = ~190
      if (deg >= 190 && deg < 212.5) return 'N';
      if (deg >= 212.5 && deg < 235) return 'NNE';
      if (deg >= 235 && deg < 257.5) return 'NE';
      if (deg >= 257.5 && deg < 280) return 'ENE';
      if (deg >= 280 && deg < 302.5) return 'E';
      if (deg >= 302.5 && deg < 325) return 'ESE';
      if (deg >= 325 && deg < 347.5) return 'SE';
      if (deg >= 347.5 && deg < 360) return 'SSE';
      if (deg >= 0 && deg < 10) return 'SSE';
      if (deg >= 10 && deg < 32.5) return 'S';
      if (deg >= 32.5 && deg < 55) return 'SSW';
      if (deg >= 55 && deg < 77.5) return 'SW';
      if (deg >= 77.5 && deg < 100) return 'WSW';
      if (deg >= 100 && deg < 122.5) return 'W';
      if (deg >= 122.5 && deg < 145) return 'WNW';
      if (deg >= 145 && deg < 167.5) return 'NW';
      if (deg >= 167.5 && deg < 190) return 'NNW';
      return deg;
    }

    // watch when the "Chart" tab is selected
    const filterChartTabMutation = (mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          if (mutation.target.classList.contains('active') || mutation.target.classList.contains('show')) {
            onResize();
            return;
          }
        }
      }
    }

    let chartTab = document.getElementById('sensor_chart');
    useMutationObserver(chartTab, filterChartTabMutation, {attributes: true,});

    onMounted(() => {
      window.addEventListener('resize', onResize);
      // draw sensor chart
      drawWindChart(props.data);
      // add ai to chart if we have the data
      if (props.ai && store.state.chart.showing_predictions) {
        updateChartAi(props.ai, props.data);
      }
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize);
    });

    return {
      svgRef,
      resizeRef,
      ttElem,
      drawLineChart: drawWindChart,
      cleanupOldChart,
      updateChartAi,
      aiToggle: computed(() => store.state.chart.showing_predictions),
    };
  },

  watch: {
    data: function dataChanged(newData) {
      this.cleanupOldChart();
      this.drawLineChart(newData);
    },
    ai: function aiInput(newAiData) {
      if (!this.aiToggle) return;
      this.updateChartAi(newAiData, this.$props.data);
    },
    aiToggle: function aiToggleChanged(newAiToggle) {
      if (newAiToggle) {
        this.updateChartAi(this.$props.ai, this.$props.data);
      } else {
        this.cleanupOldChart();
        this.drawLineChart(this.$props.data);
      }
    },
  },
}
</script>
<style>
svg {
  /* important for responsiveness */
  display: block;
  fill: none;
  stroke: none;
  width: 100%;
  height: 100%;
  overflow: visible;
}

line, path.sensor {
  stroke: var(--color-txt);
}

path.arrow {
  stroke: var(--active)!important;
  stroke-width: 2;
  fill: var(--active)!important;
}

path.aiarrow {
  stroke: #1900ff!important;
  stroke-width: 2;
  fill: #1900ff!important;
}

g.legend {
  fill: var(--color-txt);
}
</style>
