<template>
  <div ref="resizeRef">
    <svg ref="svgRef" height="400" width="1200">
    </svg>
  </div>
</template>

<script>
import {computed, onBeforeUnmount, onMounted, ref} from 'vue';
import {
  select,
  min,
  max,
  scaleTime,
  scaleLinear,
  axisBottom,
  axisLeft, brush,
} from 'd3';
import useResizeObserver from '@/components/shared/charts/resizeObserver';
import {useTippy} from 'vue-tippy';
import 'tippy.js/dist/tippy.css';
import {useStore} from "vuex";
import {useMutationObserver} from "@vueuse/core";

export default {
  name: 'D3LineChart',
  props: [
    'data',
    'ai'
  ],
  setup(props) {
    const store = useStore();
    const svgRef = ref(null);
    const {resizeRef, resizeState} = useResizeObserver();

    // chart colors
    const sensorClr = '#F7941D';
    const sensorClrSel = '#fcc888';
    const aiClr = '#1900ff';
    const aiClrSel = '#7363ff';

    // chart margins/padding
    let paddingLeft = 30;
    let paddingBtm = 50;

    // tooltip element ref
    const ttElem = ref(null);

    // time format options
    const timeOptions = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };

    // find the discrete steps between the x values (assumes the x values are sorted)
    const calcSteps = (arr) => {
      let smallestInterval = undefined;
      for (let i = 0; i < arr.length - 1; i++) {
        let diff = arr[i+1] - arr[i];
        if (smallestInterval === undefined || (diff < smallestInterval && diff > 0)) {
          smallestInterval = diff;
        }
      }
      return (max(arr) - min(arr)) / smallestInterval;
    };

    const drawBarChart = (data) => {
      const svg = select(svgRef.value);
      let x = data.map(i => i.x);
      let y = data.map(i => i.y);
      let { width, height } = resizeState.dimensions;

      if (width === 0 || height === 0) {
        let svgRect = svgRef.value.getBoundingClientRect();
        width = svgRect.width;
        height = svgRect.height;
      }

      let units = store.state.apgList.List.register_attribute?.find(i => i.attribute_name === 'register_units')?.attribute_value;
      let name = store.state.apgList.List.register_attribute?.find(i => i.attribute_name === 'register_name')?.attribute_value;
      units = units == 0 ? '' : units;
      name = name == 0 ? '' : name;
      let ylabelBuffer = units || name ? 40 : 20;

      // adjust left according to the number of digits in the max value
      paddingLeft = ylabelBuffer + parseInt(max(y)).toString().length * 5;

      if (width - paddingLeft < 0 || height - paddingBtm < 0) {
        return;
      }

      if (data.length === 0) {
        svg.append('text')
            .text('No Data Found')
            .attr('class', 'no-data')
            .attr('x', width/2 - 75)
            .attr('y', height/2)
        return;
      }

      // calculate bar width
      let steps = calcSteps(x);
      let barWidth = Math.abs((width - paddingLeft) / (steps + steps * 0.25));

      // scales
      let xScale = scaleTime()
          .domain([min(x), max(x)])
          .range([paddingLeft + barWidth/2.0 + 5, width - barWidth/2.0 - 5])

      let mxy = max(y);
      let yRatio = Math.abs(mxy) * .05;
      let yScale = scaleLinear()
          .domain([min(y) - yRatio, max(y) + yRatio])
          .range([height - paddingBtm, 10]);

      // brushing behavior
      svg.call(brush()
          .extent([[paddingLeft, 0], [width, height - paddingBtm]])
          .on('end', ({selection}) => {
            if (selection) {
              const [[x0, y0], [x1, y1]] = selection;
              let values = svg.selectAll('rect.sensor')
                  .filter(d => x0 <= xScale(d.x) && xScale(d.x) <= x1 && y0 <= yScale(d.y) && yScale(d.y) <= y1)
                  .data();

              if (values?.length === 0) {
                return;
              }

              cleanupOldChart();
              drawBarChart(values);
              svg.call(brush().clear);
            }
          }));

      // double click to reset chart
      svg.on('dblclick', () => {
        cleanupOldChart();
        drawBarChart(props.data);
      });

      // grid lines
      svg.selectAll('line.grid')
          .data(yScale.ticks())
          .enter()
          .append('line')
          .attr('class', 'grid')
          .attr('x1', paddingLeft)
          .attr('x2', width)
          .attr('y1', d => yScale(d) + 0.5)
          .attr('y2', d => yScale(d) + 0.5)
          .style('stroke-width', 0.5);

      // axes
      let xAxis = axisBottom(xScale)
      svg.append('g')
          .attr('class', 'xaxis')
          .style('transform', `translateY(${height - paddingBtm}px)`)
          .call(xAxis)

      let yAxis = axisLeft(yScale);
      svg.append('g')
          .attr('class', 'yaxis')
          .style('transform', `translateX(${paddingLeft}px)`)
          .call(yAxis);

      // y axis label
      svg.append('text')
          .attr('class', 'yaxis-label')
          .attr('transform', 'rotate(-90)')
          .attr('y', 0)
          .attr('x', 0 - (height / 2))
          .attr('dy', '1em')
          .style('text-anchor', 'middle')
          .text(`${name} ${units}`);

      // legend
      let legend = svg.append('g').attr('class', 'legend');
      legend.append("rect")
          .attr("x", width/2-100).attr("y",height-25).attr("height", 20).attr('width', 20)
          .style("fill", sensorClr);
      legend.append("text")
          .attr("x", width/2-75).attr("y", height-15)
          .text("Sensor Value")
          .style("font-size", "15px").attr("alignment-baseline","middle");

      // sensor bars
      svg.selectAll('rect.sensor')
          .data(data)
          .join('rect')
          .on('mouseenter', (e, d) => {
            select(e.target).attr('fill', sensorClrSel);
            ttElem.value = e.target;
            useTippy(ttElem, {
              arrow: true,
              allowHTML: true,
              content: () => `${d.x.toLocaleString([], timeOptions)}<br/>${d.y} ${units}`,
              inlinePositioning: true,
              showOnCreate: true,
            });
          })
          .on('mouseout', e => {
            select(e.target).attr('fill', sensorClr);
          })
          .transition()
          .attr('class', 'sensor')
          .attr('x', d => xScale(d.x) - (barWidth / 2.0))
          .attr('y', d => yScale(d.y))
          .attr('width', barWidth)
          .attr('height', d => height - paddingBtm - yScale(d.y))
          .style('fill', sensorClr)
          .style('stroke', sensorClr)

    }

    const updateChartAi = (aiData, sensorData) => {
      const svg = select(svgRef.value);
      let {width, height} = resizeState.dimensions;
      if (sensorData.length === 0 || aiData.length === 0) {
        // don't show prediction if there is no sensor data
        return;
      }
      let units = store.state.apgList.List.register_attribute?.find(i => i.attribute_name === 'register_units')?.attribute_value;
      if (units == 0)
        units = '';

      let ax = aiData.map(i => i.x);
      let sx = sensorData.map(i => i.x);
      let sy = sensorData.map(i => i.y);

      // calculate bar width
      let steps = calcSteps(sx.concat(ax));
      let barWidth = Math.abs((width - paddingLeft) / (steps + steps * 0.25));

      // update scales
      let xScale = scaleTime()
          .domain([min(sx.concat(ax)), max(sx.concat(ax))])
          .range([paddingLeft + barWidth/2.0 + 5, width - barWidth/2.0 - 5]);

      let allYs = aiData.flatMap(d => Object.values(d.y || {})).concat(sy);
      let mxy = max(allYs.filter(y => !isNaN(y)));
      let yRatio = mxy * .05;
      let yScale = scaleLinear()
          .domain([min(allYs.filter(y => !isNaN(y))) - yRatio, max(allYs.filter(y => !isNaN(y))) + yRatio])
          .range([height - paddingBtm, 10]);

      // update grid lines
      svg.selectAll('line.grid').remove();
      svg.selectAll('line.grid')
          .data(yScale.ticks())
          .enter()
          .append('line')
          .attr('class', 'grid')
          .attr('x1', paddingLeft)
          .attr('x2', width)
          .attr('y1', d => yScale(d) + 0.5)
          .attr('y2', d => yScale(d) + 0.5)
          .style('stroke-width', 0.5);

      // update axes
      let xAxis = axisBottom(xScale);
      svg.selectAll('g.xaxis').remove();
      svg.append('g')
          .attr('class', 'xaxis')
          .style('transform', `translateY(${height - paddingBtm}px)`)
          .call(xAxis)

      let yAxis = axisLeft(yScale);
      svg.selectAll('g.yaxis').remove();
      svg.append('g')
          .attr('class', 'yaxis')
          .style('transform', `translateX(${paddingLeft}px)`)
          .call(yAxis);

      // update legend
      let legend = svg.selectAll('g.legend');
      legend.append("rect")
          .attr("x", width/2+50).attr("y", height-25).attr("height", 20).attr('width', 20)
          .style("fill", aiClr);
      legend.append("text")
          .attr("x", width/2+75).attr("y", height-15).attr("alignment-baseline","middle")
          .text("AI Prediction")
          .style("font-size", "15px");

      // update existing bars
      svg.selectAll('rect.sensor')
          .join('rect')
          .transition()
          .attr('x', d => xScale(d.x) - (barWidth/2.0))
          .attr('y', d => yScale(d.y))
          .attr('width', barWidth)
          .attr('height', d => height - paddingBtm - yScale(d.y))

      const percentileAreas = [
        { lower: '0.1', upper: '0.9', opacity: 0.3, confidence: '90%' },
        { lower: '0.2', upper: '0.8', opacity: 0.4, confidence: '70%' },
        { lower: '0.3', upper: '0.7', opacity: 0.5, confidence: '50%' },
        { lower: '0.4', upper: '0.6', opacity: 0.6, confidence: '30%' }
      ];

      const getAiToolTip = (dataPoint, units) => {
        let timestamp = dataPoint.x.toLocaleString([], timeOptions);
        let confStr = '';
        for (let p of percentileAreas) {
          let lower = dataPoint.y[p.lower];
          let upper = dataPoint.y[p.upper];
          confStr += `${p.confidence}: ${lower.toFixed(2)} - ${upper.toFixed(2)} ${units}<br/>`;
        }
        return `${timestamp}<br/>Mean: ${dataPoint.y['0.5'].toFixed(2)}<br/>${confStr}`;
      }

      // add new ai bars
      percentileAreas.forEach(({lower, upper, opacity}) => {
        svg.selectAll('rect.ai')
            .data(aiData)
            .join('rect')
            .on('mouseenter', (e, d) => {
              select(e.target).attr('fill', aiClrSel);
              ttElem.value = e.target;
              useTippy(ttElem, {
                arrow: true,
                allowHTML: true,
                content: () => getAiToolTip(d, units),
                inlinePositioning: true,
                showOnCreate: true,
              });
            })
            .on('mouseout', e => {
              select(e.target).attr('fill', aiClr);
            })
            .transition()
            .attr('class', 'ai')
            .attr('x', d => xScale(d.x) - (barWidth/2.0))
            .attr('y', d => yScale(d.y[upper]))
            .attr('width', barWidth)
            .attr('height', d => yScale(d.y[lower]) - yScale(d.y[upper]))
            .style('fill', aiClr)
            .style('stroke', aiClr)
            .style('opacity', opacity)
      });
    };

    const cleanupOldChart = () => {
      const svg = select(svgRef.value);
      svg.selectAll('svg > *').remove();
    };

    const onResize = () => {
      cleanupOldChart();
      drawBarChart(props.data);
    };

    // watch when the "Chart" tab is selected
    const filterChartTabMutation = (mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          if (mutation.target.classList.contains('active') || mutation.target.classList.contains('show')) {
            onResize();
            return;
          }
        }
      }
    }
    let chartTab = document.getElementById('sensor_chart');
    useMutationObserver(chartTab, filterChartTabMutation, {attributes: true,});

    onMounted(() => {
      window.addEventListener('resize', onResize);
      drawBarChart(props.data);
      if (props.ai && store.state.chart.showing_predictions) {
        updateChartAi(props.ai, props.data);
      }
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize);
    });

    return {
      svgRef,
      resizeRef,
      resizeState,
      ttElem,
      drawBarChart,
      cleanupOldChart,
      updateChartAi,
      aiToggle: computed(() => store.state.chart.showing_predictions),
    };
  },

  watch: {
    data: function dataChanged(newData) {
      this.cleanupOldChart();
      this.drawBarChart(newData);
    },
    ai: function aiInput(newAiData) {
      if (!this.aiToggle) return;
      this.updateChartAi(newAiData, this.$props.data);
    },
    aiToggle: function aiToggleChanged(newAiToggle) {
      if (newAiToggle) {
        this.updateChartAi(this.$props.ai, this.$props.data);
      } else {
        this.cleanupOldChart();
        this.drawBarChart(this.$props.data);
      }
    },
  },
}
</script>
<style>
svg {
  /* important for responsiveness */
  display: block;
  fill: none;
  stroke: none;
  width: 100%;
  height: 100%;
  overflow: visible;
}

line, path.sensor {
  stroke: var(--color-txt);
}

legend {
  fill: var(--color-txt);
}

text.no-data {
  fill: var(--color-txt);
}
</style>
