<template>
  <div>
    <nav class="navbar navbar-expand-md justify-content-around min-vw-100">
      <div class="navbar-nav logoHeader">
        <img class="img-fluid"/>
        <a href="/" class="navbar-brand"></a>
      </div>

      <!--      Hamburger when small screen-->
      <button class="navbar-toggler navbar-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle Navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav text-nowrap">
          <li class="nav-item">
            <router-link v-if="checkAccess('/home')" to="/home" class="nav-link text-end">
              <font-awesome-icon icon="home"/>
              <b>Home</b>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link v-if="checkAccess('/admin')" to="/admin" class="nav-link text-end">
			<font-awesome-icon icon="user-secret"/>
			<b>Admin Board</b>
			</router-link>
          </li>
          <li class="nav_item">
            <router-link v-if="checkAccess('/gateways')" to="/gateways" class="nav-link text-end"><b>My View</b></router-link>
          </li>
          <li class="nav_item">
            <router-link v-if="checkAccess('/auditDB')" to="/auditDB" class="nav-link text-end"><b>Audit DB</b></router-link>
          </li>
        </ul>
      </div>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link text-end" @click="toggleDark">
              <font-awesome-icon v-if="isDark" icon="sun"/>
              <font-awesome-icon v-else icon="moon"/>
            </a>
          </li>
        </ul>
        <ul v-if="!currentUser" class="navbar-nav">
          <li class="nav-item text-end">
            <router-link to="/register" class="nav-link">
              <font-awesome-icon icon="user-plus"/>
              Sign Up
            </router-link>
          </li>
          <li class="nav-item text-end">
            <router-link to="/login" class="nav-link">
              <font-awesome-icon icon="sign-in-alt"/>
              Login
            </router-link>
          </li>
        </ul>

        <ul v-if="currentUser" class="navbar-nav">
          <li v-if="checkAccess('/profile')" class="nav-item text-end">
            <router-link to="/profile" class="nav-link">
              <font-awesome-icon icon="user"/>
              {{ currentUser?.username }}
            </router-link>
          </li>
          <li class="nav-item text-end">
            <a class="nav-link" @click.prevent="logOut">
              <font-awesome-icon icon="sign-out-alt"/>
            </a>
          </li>
        </ul>
      </div>
    </nav>

    <div class="container-fluid">
      <router-view/>
    </div>
  </div>
</template>

<script>
import {useDark, useToggle} from '@vueuse/core';
import routeAccessService from "@/services/routeAccess.service";
import socketInstance from "@/services/Socket";

export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
      socketInstance.resetSocket();
    },
    toggleDark() {
      let darkMode = this.isDark;
      darkMode = !darkMode;
      // css toggle
      this.darkToggle(darkMode);
    },
    checkAccess(path){
      let user_type = this.currentUser?.user?.Info?.user_type;
      let allowedPages = routeAccessService(user_type);
      return allowedPages.includes(path);
    },
  },
  data() {
    return {
      isDark: false,
    }
  },
    setup() {
    const dark = useDark();
    const darkToggle = useToggle(dark);
    return {
      darkToggle,
    }
  },
  mounted() {
    this.isDark = useDark();
  }
}
</script>

<style>
.navbar-collapse.show {
  padding-right: 5%;
}
.navbar-collapse {
  flex-grow:0!important;
}
</style>