<template>
  <div class="card" style="margin-top: 10px; height: 50vh; display: flex; flex-direction: column;">
    <div class="card-header">Alarm History</div>
    <div class="card-block table-responsive" style="flex: 1; overflow-y: auto;">
      <table class="table table-bordered text-nowrap">
        <tr class="sticky-top-z1">
          <th>ID</th>
          <th>Type</th>
          <th>Alarm Value</th>
          <th>Sensor Value</th>
          <th>Triggered On</th>
          <th>Received On</th>
          <th>Status</th>
          <th>Acknowledged On</th>
          <th>Acknowledged By</th>
          <th>Acknowledge</th>
        </tr>
        <tbody>
        <tr v-for="(alarm) in alarms" :key="alarm" :class="{ 'blink_me table-danger': alarm.status == 'Active'}">
          <td style="text-align: center">{{ alarm.alarm_data_id }}</td>
          <td style="text-align: center">{{ alarm.alarm_type_id }}</td>
          <td style="text-align: center">{{ alarm.alarm_set_point }}</td>
          <td style="text-align: center">{{ alarm.sensor_value }}</td>
          <td style="text-align: center">{{ moment(alarm.trigger_dt) }}</td>
          <td style="text-align: center">{{ moment(alarm.received_dt) }}</td>
          <td style="text-align: center">{{ alarm.status }}</td>
          <td style="text-align: center">{{ moment(alarm.cleared_dt) }}</td>
          <td style="text-align: center">{{ alarm.cleared_by_user }}</td>
          <td style="text-align: center"><button v-if="alarm.status == 'Active'" class="form-control" @click="AcknowledgeAlarm(alarm.alarm_data_id)"><font-awesome-icon icon="check" /></button></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
export default {
  
  components: {
  }, 
  methods: {
      moment: function (date) {
        if (date != null) {
          return moment(date).format('MMM Do YY, h:mm:ss a');
        } else  {
          return '';
        }
      }
    },
  setup() {
    let store = useStore()
    
    const AcknowledgeAlarm = function (alarm_data_id) {
        store.dispatch("alarm/AcknowledgeAlarm", {alarm_data_id:alarm_data_id});
    }

    return {
      alarms: computed(() => store.state.apgList.List['alarm_history']),
      AcknowledgeAlarm,
    }
  }
}
</script>
