import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { FontAwesomeIcon } from './plugins/font-awesome'
import axios from "axios"
import Toast, { TYPE } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import '@/scss/main.scss'

createApp(App)
 .use(router)
 .use(store)
 // App.use(BootstrapVue3)
  .use(Toast, {
    toastDefaults: {
      [TYPE.SUCCESS]: {
        transition: 'Vue-Toastification__fade',
        maxToasts: 5,
        position: 'bottom-right',
        timeout: 2000,
        icon: true,
        hideProgressBar: true,
        closeOnClick: true
      },
      [TYPE.WARNING]: {
        transition: 'Vue-Toastification__fade',
        maxToasts: 5,
        position: 'bottom-right',
        timeout: 2500,
        icon: true,
        hideProgressBar: true,
        closeOnClick: true
      },
      [TYPE.ERROR]: {
        transition: 'Vue-Toastification__fade',
        maxToasts: 5,
        position: 'bottom-right',
        timeout: 2500,
        icon: true,
        hideProgressBar: true,
        closeOnClick: true
      }
    }
  })
 .component("font-awesome-icon", FontAwesomeIcon)
 .mount("#app");

 
axios.interceptors.request.use(
    function(successfulReq) {
        let user = JSON.parse(localStorage.getItem('user'))
        console.log ('Axios Interceptor');
        if (user && user.token) {
            successfulReq.headers['Authorization'] = 'Bearer ' + user.token
            return successfulReq;
        } else {
            console.log('you fail ; There is no token')
            router.push('/Login')
        }
        
      }, 
      function(error) {
        console.log ('Axios Interceptor ; Here is the error ' , error);
        return Promise.reject(error);
      }
  );

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if(error.response.status == 401){
            console.log('you are unauthorized ; GET OUT SUCKER (or your token expired ; Either way, get out)')
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            router.push('/Login')
        }
    }
);
