<template>
<ul class="card-header" style="list-style-type: none; padding-left: 5%">
Register
<li v-for="reg in registers" :key="reg" class="clearfix">
  <button @click="updateRegToRead(reg.register)" :class="{ active: selectedReg(reg.register) }">
    <span class="fa-pull-left">{{reg.name? 'Name:' : 'Register:'}}</span>
    <span class="fa-pull-right">{{reg.name? reg.name : reg.register}}</span>
    <br/>
    <span class="fa-pull-left">Latest Reading:</span>
    <span class="fa-pull-right">{{reg.value}} {{reg.units}}</span>
  </button>
</li>
</ul>
</template>

<script>
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: 'ReadRegisterList',
  setup() {
    const store = useStore();
    const updateRegToRead = function (value) {
      store.commit('settings/setIsLoading', true);
      store.commit('chart/clearData');

      store.dispatch("sensor/updateRegisterToRead", { value: value, sensor_id: store.state.sensor.id })
          .then(async () => {
            await store.dispatch('sensor/findInfo', { id: store.state.sensor.id, listType: 'group_gateway_sensor', chart: true })
            await store.dispatch('sensor/findInfoOverview', {id: store.state.sensor.id});
            await store.dispatch('sensor/getRegisters', {sensor_id: store.state.sensor.id});
            await store.commit('settings/setIsLoading', false);
      });
    };

    const selectedReg = function (reg) {
      let store = useStore();
      let sensorOverview = store.state.apgList.List?.sensor_overview;
      if (!sensorOverview) return;
      let selected = sensorOverview?.find(x => x.attribute_name == 'addressToRead')?.attribute_value;
      return selected == reg;
    };

    return {
      registers: computed(() => {
        let registers = store.state.apgList.List?.sensor_registers;
        if (!registers) return;

        // group by register
        let regGroups = registers.reduce((r, a) => {
          r[a.register] = [...r[a.register] || [], a];
          return r;
        }, {});

        // format for display
        let regList = [];
        for (let idx in regGroups) {
          let reg = regGroups[idx];
          let name = reg.find(x => x.attribute_name == 'register_name')?.attribute_value;
          let val = reg.find(x => Object.hasOwn(x,'sensor_data_value'))?.sensor_data_value;
          let unit = reg.find(x => x.attribute_name == 'register_units')?.attribute_value;
          let register = reg.find(x => Object.hasOwn(x,'register'))?.register;
          regList.push({
            name: name,
            value: val,
            units: unit,
            register: register,
          });
        }
        return regList;
      }),
      updateRegToRead,
      selectedReg,
    }
  }
}
</script>

<style scoped>
li {
  font-size: 10pt;
}
button {
  width: 100%;
  background-color: var(--color-bg-sec);
  color: var(--color-txt);
  transition: background 0.5s ease, color 0.5s ease;
}
.active {
  box-shadow: none!important;
  background-color: var(--btn-pressed);
  transition: background 0.5s ease, color 0.5s ease;
}
</style>
