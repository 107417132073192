import axios from 'axios'
import { useToast } from 'vue-toastification';

const toast = useToast();

export const gateway = {
  namespaced: true,

  state: {
    url_part: 'gateways',
    url_gateway: 'gateway/info',
    url_gateway_sales: 'gateway/sales/info',
    url_gateways_for_group: 'gateways/group/',
    url_gateways_for_group_me: 'group/gateways/me/',
    url_remove_gateway_from_group: 'gateway/remove_group/',
    url_add_gateways_to_group: 'group/add_gateways/',
    url_group_for_gateway: 'groups/gateway/',
    url_gateways_reading_control: 'gateways/readingControl/',
    url_gateways_GPS_location: 'gateways/GPSLocation/',
    url_gateways_function_code: 'gateways/changeFunction/',
    url_gateways_update_GCR: 'gateways/updateGCR/',
    url_gateways_me: 'gateways/me/',
    url_CommUsage: 'gateways/commUsage/',
    url_gateways_queued_message: 'gateways/queuedMessage/',
    url_gateways_sent_message: 'gateways/sentMessage/',
    url_gateways_send_message: 'gateways/sendMessage/',
    url_gateways_alarms: 'gateways/Alarm/',
    url_remove_message : 'gateways/removeMessage/',
    url_gateway_overview : 'gateways/overview/',
    url_gateway_timings : 'gateways/timings/',
    url_gateway_registration : 'gateways/register'
  },
  mutations: {},
  actions: {
    async AddNewGCR({ commit }) {
      let json = {
        gateway_reading_control_id: '0',
        sensor_address: '1',
        sensor_register: '0',
        sensor_type_name: 'Modbus',
        power_line: 1,
        application_type: '04',
        application_type_description:
          'Request to Nomad to read modbus input reg (MD F4)',
        length: 1,
        status: 'new',
      }
      commit(
        'apgList/addNew',
        { listType: 'gateway_control_reading', data: json },
        { root: true }
      )
    },
    async SendGatewayReadingControl({ rootState, state, dispatch }) {
      let payload = {id: rootState.apgList.ids.gateway.gateway};
      // send the timing data too
      dispatch('sendGatewayTimings', payload);

      let gcrTemp = {
        gatewayId: rootState.apgList.ids.gateway.gateway,
        data: rootState.apgList.List.gateway_control_reading,
      }
      let gcr = JSON.stringify(gcrTemp)

      let url = rootState.settings.api_base + state.url_gateways_update_GCR
      try {
        let rs = await axios.patch(url, {data: { gcr }});
        console.log('result update GCR = ', rs.data);
        dispatch('findGatewayReadingControl', payload);
        dispatch('findQueuedMessages', payload);
        toast.success('Queued GRC messages!');
      } catch (err) {
        console.error('Failed to update GCR ', err)
        toast.error('Unable to queue GRC messages. Please try again later.');
      }
    },
    async getCommUsage({ rootState, state, commit }) {
      let current_user_id = rootState.auth.user.user.Auth.system_user_id
      console.log('find all gateways comm usage for current user ' + current_user_id);
      let url = rootState.settings.api_base + state.url_CommUsage + current_user_id;

      try {
        let rs = await axios.get(url)
        console.log('found gateways comm usage list for me ' + current_user_id, rs.data);
        commit(
          'apgList/setList',
          { listType: 'gateway_comm_usage', data: rs.data.List },
          { root: true }
        )
      } catch (err) {
        console.error('Failed to load my gateway list for me.  Error was ')
        console.error({ err })
      }
    },

    async updateStoreGatewayReadingControl({ rootState, commit }, payload) {
      try {
        commit(
          'apgList/setSingle',
          {
            listType: 'gateway_control_reading',
            index: payload.array_idx,
            name: payload.item,
            data: payload.value,
          },
          { root: true }
        )
        let currentStatus =
          rootState.apgList.List.gateway_control_reading[payload.array_idx][
            'status'
          ]
        if (currentStatus != 'new') {
          commit(
            'apgList/setSingle',
            {
              listType: 'gateway_control_reading',
              index: payload.array_idx,
              name: 'status',
              data: 'updated',
            },
            { root: true }
          )
        }
      } catch (err) {
        console.error('Failed to update store for GRC ', err)
      }
    },
    async removeGRC({ commit }, payload) {
      commit(
        'apgList/setSingle',
        {
          listType: 'gateway_control_reading',
          index: payload.id,
          name: 'status',
          data: 'deleted',
        },
        { root: true }
      )
    },

    async changeFunctionGatewayReadingControl(
      { rootState, state, commit },
      payload
    ) {
      let url =
        rootState.settings.api_base +
        state.url_gateways_function_code +
        payload.value
      try {
        let rs = await axios.get(url)
        commit(
          'apgList/setSingle',
          {
            listType: 'gateway_control_reading',
            index: payload.array_idx,
            name: 'application_type_description',
            data: rs.data.Info[0].application_type_description,
          },
          { root: true }
        )
        commit(
          'apgList/setSingle',
          {
            listType: 'gateway_control_reading',
            index: payload.array_idx,
            name: 'application_type',
            data: payload.value,
          },
          { root: true }
        )
        let currentStatus =
          rootState.apgList.List.gateway_control_reading[payload.array_idx][
            'status'
          ]
        if (currentStatus != 'new') {
          commit(
            'apgList/setSingle',
            {
              listType: 'gateway_control_reading',
              index: payload.array_idx,
              name: 'status',
              data: 'updated',
            },
            { root: true }
          )
        }
      } catch (err) {
        console.error('Failed to get reading control function ', err)
      }
    },
    async findAll({ rootState, state, commit }, payload) {
      let user = JSON.parse(localStorage.getItem('user'))
      try {
        let rs = await axios.get(rootState.settings.api_base + state.url_part, {
          params: { user_id: user.user.system_user_id },
        })
        console.log('Gateway response was ', rs)
        commit(
          'apgList/setLists',
          { listType: payload.listType, data: rs.data },
          { root: true }
        )
      } catch (err) {
        console.error('Failed to load gateway list.  Error was ', err)
      }
    },
    async findQueuedMessages({ rootState, state, commit }, payload) {
      let id = payload.id;
      console.log('find all queued message(s) for ', id);
      let url = rootState.settings.api_base + state.url_gateways_queued_message + id;

      try {
        let rs = await axios.get(url);
        console.log('found queued message ', rs.data);
        commit(
          'apgList/setList',
          { listType: 'gateway_queued_message', data: rs.data.queuedMessage },
          { root: true }
        );
      } catch (err) {
        console.error('Failed to retrieve queued message(s).  Error was ', err);
      }
    },
    async findAlarmGateway({ rootState, state, commit }, payload) {
      let id = payload.id
      console.log('find all alarms(s) for ', id)
      let url = rootState.settings.api_base + state.url_gateways_alarms + id

      commit(
        'apgList/clearDropdownValues',
        { table: 'alarm_type' },
        { root: true }
      )
      commit(
        'apgList/clearDropdownValues',
        { table: 'alarm_action_type' },
        { root: true }
      )

      try {
        let rs = await axios.get(url)
        console.log('found alarm ', rs.data)
        commit('apgList/setList',{ listType: 'alarm_set', data: rs.data.AlarmSet },{ root: true })
        commit('apgList/setList',{ listType: 'alarm_history', data: rs.data.AlarmHistory },{ root: true })
        commit('apgList/setList', { listType:"alarm_count_active", data: rs.data.AlarmCountActive }, {root: true})
        
        let arr = rs.data.AlarmSet || []
        //console.log('findAlarmGateway alarm set is',rs.data.AlarmSet)
        for (let i = 0; i < arr.length; i++) {
          let obj = {
            idx: i,
            table: 'alarm_type',
            data: rs.data.AlarmSet[i].alarm_type_id,
          }
          //console.log('FindAlarmGateway setting values to',obj)
          commit('apgList/setDropdownValue', obj, { root: true })
          let obj2 = {
            idx: i,
            table: 'alarm_action_type',
            data: rs.data.AlarmSet[i].alarm_action_type_id,
          }
          //console.log('FindAlarmGateway setting values to',obj)
          commit('apgList/setDropdownValue', obj2, { root: true })          
        }
      } catch (err) {
        console.error('Failed to retreive alarm(s).  Error was ')
        console.error({ err })
      }
    },
    async findSentMessages({ rootState, state, commit }, payload) {
      console.log('find all sent message(s) for ', payload.id);
      let url = rootState.settings.api_base + state.url_gateways_sent_message + payload.id;
      console.log('url ', url);
      try {
        let rs = await axios.get(url)
        console.log('found sent message ', rs.data);
        commit('apgList/setList', { listType: 'gateway_sent_message', data: rs.data.sentMessage }, { root: true });
      } catch (err) {
        console.error('Failed to retrieve sent message(s).  Error was ')
        console.error({ err })
      }
    },
    async findGatewayGPSLocation({ rootState, state, commit }, payload) {
      let id = payload.id
      console.log('find all gateways GPS Location for ', id)
      let url =
        rootState.settings.api_base + state.url_gateways_GPS_location + id

      try {
        let rs = await axios.get(url)
        console.log('found gateways GPS Location ', rs.data)
        commit('apgList/setList', { listType:"gateway_GPS_Location", data: rs.data.Info }, {root: true})
        //state.readingControl = rs.data
      } catch (err) {
        console.error('Failed to load gateway GPS Location.  Error was ')
        console.error({ err })
      }
    },

    async findGatewayReadingControl({ rootState, state, commit }, payload) {
      let id = payload.id
      console.log('find all gateways Reading control for ', id)
      let url =
        rootState.settings.api_base + state.url_gateways_reading_control + id

      try {
        let rs = await axios.get(url)
        console.log('found gateways reading control ', rs.data)
        commit('apgList/setList', { listType:"gateway_control_reading", data: rs.data.Info }, {root: true})
        commit('apgList/setID',{ listType: 'gateway', idType: 'gateway', _id: id },{ root: true })
        //state.readingControl = rs.data
      } catch (err) {
        console.error('Failed to load gateway reading control.  Error was ')
        console.error({ err })
      }
    },

    async findOne({ rootState, state, commit }, payload) {
      let url = rootState.settings.api_base +state.url_gateway +'/' + payload.id;
      console.log(url)

      try {
        let rs = await axios.get(url)
        console.log('gateway info is', rs)
        commit(
          'apgList/setList',
          { listType: payload.listType, data: rs.data.Attributes },
          { root: true }
        )
        commit('status_bar/setStatus', rs.data.status, { root: true })
      } catch (err) {
        console.error('Failed to load user info.  Error was ')
        console.error({ err })
      }
    },
    async findAllForGroup({ rootState, state, commit }, payload) {
      console.log(
        'find all gateways for group ',
        rootState.apgList.ids['group'].group_id
      )
      let url =
        rootState.settings.api_base +
        state.url_gateways_for_group +
        rootState.apgList.ids['group'].group_id

      try {
        let rs = await axios.get(url)
        console.log('found gateways list for group', rs.data)
        if (typeof payload !== 'undefined') {
          commit(
            'apgList/setLists',
            { listType: payload.listType, data: rs.data },
            { root: true }
          )
        } else {
          return rs.data
        }
      } catch (err) {
        console.error('Failed to load gateway info for group.  Error was ')
        console.error({ err })
      }
    },async findInfoOverview({ rootState, state, commit }, payload) {
      let url = rootState.settings.api_base + state.url_gateway_overview + payload.id;
      try {
        let rs = await axios.get(url)
        commit('apgList/setList', { listType: "gateway_overview", data: rs.data.Attributes},{root: true});
      }catch (err) {
        console.error('Failed to load my gateway overview.  Error was ')
        console.error({ err })
      }
    },
    async findAllForGroupMe({ rootState, state, commit }, payload) {
      let group_id = payload.group_id
      console.log('find all gateways for group ' + group_id)
      let url =
        rootState.settings.api_base + state.url_gateways_for_group_me + group_id

      try {
        let rs = await axios.get(url)
        console.log('found gateways list for group ' + group_id, rs.data)
        if (typeof payload !== 'undefined') {
          commit(
            'MyView/addChildrenToParent',
            { listType: payload.listType, idx: payload.idx, data: rs.data },
            { root: true }
          )
        }
      } catch (err) {
        console.error('Failed to load my gateway info for group.  Error was ')
        console.error({ err })
      }
    },
    async findAllForMe({ rootState, state, commit }) {
      let current_user_id = rootState.auth.user.user.Auth.system_user_id
      console.log('find all gateways for current user ' + current_user_id)
      let url =
        rootState.settings.api_base + state.url_gateways_me + current_user_id

      try {
        let rs = await axios.get(url)
        console.log('found gateways list for me ' + current_user_id, rs.data)
        commit(
          'apgList/setList',
          { listType: 'gateway_me', data: rs.data.List },
          { root: true }
        )
      } catch (err) {
        console.error('Failed to load my gateway list for me.  Error was ')
        console.error({ err })
      }
    },
    async removeGatewayFromGroup({ rootState, state, dispatch }, payload) {
      let url =
        rootState.settings.api_base +
        state.url_remove_gateway_from_group +
        rootState.apgList.ids['group_gateway'].gateway_id +
        '/' +
        rootState.apgList.ids['group'].group_id
      console.log('removing gateway url is', url);
      try {
        await axios.delete(url);
        dispatch('findAllForGroup', payload);
        toast.success('Removed gateway from group!');
      } catch (err) {
        console.error('Failed to remove gateway from group.  Error was ');
        console.error({ err });
        toast.error('Unable to remove gateway from group. Please try again later.');
      }
    },
    async removeGroupFromGateway({ rootState, state, dispatch }) {
      let url =
        rootState.settings.api_base +
        state.url_remove_gateway_from_group +
        rootState.apgList.ids['gateway'].gateway +
        '/' +
        rootState.apgList.ids['gateway_group'].group_id
      console.log('removing group from Gateway url is', url);
      try {
        await axios.delete(url);
        dispatch('findAllForGateway', { listType: 'gateway_group' });
        toast.success('Removed group from gateway!');
      } catch (err) {
        console.error('Failed to remove gateway from group.  Error was ');
        console.error({ err });
        toast.error('Unable to remove group from gateway. Please try again later.');
      }
    },
    async findAllForGateway({ rootState, state, commit }, payload) {
      let url =
        rootState.settings.api_base +
        state.url_group_for_gateway +
        rootState.apgList.ids['gateway'].gateway

      try {
        let rs = await axios.get(url)
        console.log('found groups list for gateway', rs.data)
        commit(
          'apgList/setLists',
          { listType: payload.listType, data: rs.data },
          { root: true }
        )
      } catch (err) {
        console.error('Failed to load group list for gateway.  Error was ')
        console.error({ err })
      }
    },    

    async sendMessage({state,dispatch,rootState}, payload) {
      let url = rootState.settings.api_base + state.url_gateways_send_message + payload.id  ;
      console.log('url ', url);
      try {
        await axios.patch(url);
        dispatch('findQueuedMessages', payload);
        dispatch('findSentMessages', payload);
        toast.success('Messages sent to gateway!');
      }
      catch(err) {
        console.log("removing message: error was: ", err);
        toast.error('Unable to send messages to gateway. Please try again later.');
      }      
    },

    async removeMessage ({state,dispatch,rootState}, payload) {
      let url = rootState.settings.api_base + state.url_remove_message + payload.send_data_queue_id  ;
      console.log('url ',url)
      try {
        await axios.patch(url,{})
        // update message center
        dispatch('findQueuedMessages', payload);
        // update overview
        dispatch('findInfoOverview', {id: rootState.apgList.ids.gateway.gateway});
      } catch(err) {
        console.log("removing message: error was: ", err)
      }      
    },
   
    async addGatewaysToGroup({ state, dispatch, rootState, commit }, payload) {
      let checked = rootState.apgList?.checked?.[payload.listType];
      if (!checked || checked.length === 0) {
        return;
      }

      let gateway_list = checked.join('-');
      let gtwy_str = checked.length > 1 ? 'gateways' : 'gateway';
      console.log('gateway list is ', gateway_list)
      let url =
        rootState.settings.api_base +
        state.url_add_gateways_to_group +
        rootState.apgList.ids['group'].group_id +
        '/' +
        gateway_list
      console.log('url ', url)
      try {
        let rs = await axios.put(url, {});
        console.log('addGatewaystoGroup', rs);
        commit('apgList/clearChecked', payload, { root: true });
        dispatch('findAllForGroup', payload);
        toast.success(`Added ${gtwy_str} to group!`);
      } catch (err) {
        console.log('addGatewaysToGroup: error was: ', err);
        toast.error(`Unable to add ${gtwy_str} to group. Please try again later.`);
      }
    },
    async findGatewayTimings({rootState, state, commit}, payload) {
      let url = rootState.settings.api_base + state.url_gateway_timings + payload.id;

      console.log('url', url);
      try {
        let rs = await axios.get(url);
        console.log('gatewayTimings: ', rs);
        commit('apgList/setList', {listType: 'gateway_timings', data: rs.data}, {root: true});
      } catch (error) {
        console.error('findGatewayTimings: error was: ', error);
      }
    },
    async sendGatewayTimings({rootState, state, dispatch}, payload) {
      let url = rootState.settings.api_base + state.url_gateway_timings + payload.id;
      console.log('url', url);

      // compile data
      let user_id = rootState.auth.user.user.Auth.system_user_id;
      let data = [];
      // loop through the timings
      for (let attr of rootState.apgList.List.gateway_timings) {
        // only grab the timings that have changed
        if (attr.status && attr.status === 'changed') {
          data.push({
            attr_info_id: attr.attribute_info_id,
            user_id: user_id,
            attr_name: attr.attribute_name,
            attr_value: attr.attribute_value,
            pretty_name: attr.attribute_pretty_name,
          });
        }
      }
      console.log('timing post data', data);

      // off it goes
      try {
        await axios.post(url, {data: data});
      } catch (error) {
        console.error('sendGatewayTimings: error was: ', error);
      }

      // update the table
      dispatch('findGatewayTimings', payload);
    },
    async createRawMessage({commit}, payload) {
      let rawMsgForm = {
        command_pretty_name: 'User Created Raw Message',
        last_update_ts: payload.ts,
        last_update_user_id: payload.user_id,
        username: payload.username,
        gateway_id: payload.gateway_id,
      }
      commit('apgList/addNew', {listType: 'gateway_queued_message', data: rawMsgForm}, {root: true});
    },
    async saveRawMessage({rootState, state, dispatch}, payload) {
      let url = rootState.settings.api_base + state.url_gateways_queued_message + payload.gateway_id;
      console.log('save raw msg', url);

      try {
        await axios.post(url, {...payload});
        // update message center
        dispatch('findQueuedMessages', {id: payload.gateway_id});
        // update overview
        dispatch('findInfoOverview', {id: payload.gateway_id});
      } catch (e) {
        console.error('save raw message: error:', e);
      }
    },
    async registerSwarmDevice({rootState, state, dispatch}, payload) {
      let rawString = JSON.parse(payload.decoded ?? '');
      let authCode = rawString?.ac ?? '';

      let url = rootState.settings.api_base + state.url_gateway_registration;
      console.log('url', url);
      try {
        let res = await axios.post(url,
          {
            ac: authCode,
            user_id: rootState.auth.user.user.Auth.system_user_id,
          });

        // update list of gateways
        dispatch('findAll', {listType: 'gateway'});
        return res.status === 200;
      } catch (e) {
        console.error('register new swarm device:', e);
        return false;
      }
    },
    async updateGatewayStatus({rootState, state}, payload) {
      let url = rootState.settings.api_base + state.url_gateway_registration;
      console.log('url', url);

      try {
        let res = await axios.patch(url, {
          gatewayId: rootState.apgList.ids.gateway.gateway,
          userId: rootState.auth.user.user.Auth.system_user_id,
          status: payload.value,
        });

        // toast
        if (res.status === 200) {
          toast.success(`Gateway status successfully ${payload.value === true? 'updated!' : 'removed!'}`);
        } else {
          toast.error('Failed to update gateway status. Please try again later.');
        }
      } catch (e) {
        toast.error('Failed to update gateway status. Please try again later.');
        console.error('update gateway status:', e);
      }
    },
  },
  getters: {},
}
