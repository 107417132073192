import axios from 'axios'

const attribute_keys = {
  id: 0,
  list: [],
  activeIndex: 0,
}

export const attributes = {
  namespaced: true,

  state: {
    attribute_list: {
      'group': {
        ...attribute_keys,
      },
      'user': {
        ...attribute_keys,
      },
      'gateway': {
        ...attribute_keys
      },
      'sensor': {
        ...attribute_keys
      },
   },
  },

  mutations: {
    setList({state}, payload) {
      state.attribute_list[payload.listType].list = payload.list;
    },
    setActiveId({state}, payload) {
      state.attribute_list[payload.listType].id = payload.id;
    },
    setActiveKey({state}, payload) {
      state.attribute_list[payload.listType].id = payload.id;
    },    
    setAttribute({commit}, payload) {
      commit('setList', payload)
      commit('setActiveId', payload)
    },
    setAttributeValue({state, commit}, payload) {
      commit('setActiveId', payload)
      state.attribute_list[payload.listType].list[payload.idx][payload.attributeKey] = payload.newValue;
    },
  },
  actions: {
    async findAll ({ rootState, commit }, payload) {
      let url = rootState.settings.api_base + 'attributes/' + payload.listType
      try {
        let rs = await axios.get(url)
        console.log('Findall Attributes response was ', rs)
        commit('setList', { listType:payload.listType, list: rs.data.list })
      } catch (err) {
        console.error('Failed to attribute list for ' + payload.listType + ' Error was ', err)
      }
    },
    async update({ state }, payload) {
      let currentId = state.attribute_list[payload.listType].id
      // let url = rootState.settings.api_base + '/attribute/' + payload.listType
      let newValue = state.attribute_list[payload.listType].list[payload.idx]
      let data = { id: currentId, value: newValue }
      console.log('setting attribute to new value', data)
      /*
      try {
        let rs = await axios.put(url)
        console.log('Findall Attributes response was ', rs)
        commit('setList', { listType:payload.listType, list: rs.data.list })
      } catch (err) {
        console.error('Failed to attribute list for ' + payload.listType + ' Error was ', err)
      }
      */
    },    
  },
  getters: {},
}