<template>
<div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
       Chart filters 
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
					<div class='row'>
						<div class='col-md-6'>
							Ranges
						</div>
						<div class='col-md-6'>
								<input type='radio' name="date_ranges" class='form-check-input' :checked="daterange === 'custom'" 
										@change="updateDateRange('custom')" value='custom' />
								<label class='form-check-label' style='margin-left:5px'> Custom</label>
						</div>
					</div>
					<div class='row'>
						
						<div class='col-md-6'>
							<div class='form-check'>
								<input type='radio' name="date_ranges" class='form-check-input' :checked="daterange === '30r'" @change="updateDateRange('30r')" value='30r' />
								<label class='form-check-label'> Last 30 readings </label>
							</div>
						</div>
						<div class='col-md-6'>
								<div class='row' v-if="daterange=='custom'">
									<div class='col-md-3'>From</div>
									<div class='col-md-6'>
                    <input type='date' class='form-control'  :value="fromDate" @change="setFromDate" />
                  </div>
								</div>
						</div>
					</div>
					<div class='row'>
						<div class='col-md-6'>
							<div class='form-check'>
								<input type='radio' name="date_ranges" class='form-check-input' :checked="daterange === '24h'" @change="updateDateRange('24h')" value='24h' />
								<label class='form-check-label'> Last 24 hours </label>
							</div>
						</div>
						<div class='col-md-6'>
								<div class='row' v-if="daterange=='custom'">
									<div class='col-md-3'>To</div>
									<div class='col-md-6'>
                    <input type='date' class='form-control' :value="toDate" @change="setToDate" />
                  </div>
								</div>
						</div>
					</div>
					<div class='row'>
						<div class='col-md-6'>
							<div class='form-check'>
								<input type='radio' name="date_ranges" class='form-check-input' :checked="daterange === '30d'" @change="updateDateRange('30d')" value='30d' />
								<label class='form-check-label'> Last 30 days </label>
							</div>
						</div>
						<div class='col-md-6'>
						</div>
					</div>
					<div class='row'>
						<div class='col-md-6'>
							<div class='form-check'>
								<input type='radio' name="date_ranges" class='form-check-input' :checked="daterange === '7d'" @change="updateDateRange('7d')" value='7d' />
								<label class='form-check-label'> Last 7 days </label>
							</div>
						</div>
					</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
	import { useStore } from "vuex"
	import {computed, onMounted} from 'vue'
		
	export default {
		props: {
      listType: String,
    },
		setup(props) {
			const store = useStore();
      let fromDate = computed(() => store.state.chart.filters.dateRange.fromDate[props.listType]);
      let toDate = computed(() => store.state.chart.filters.dateRange.toDate[props.listType]);

			function updateDateRange(_val) {
				console.log('value dateRange', _val)
				store.commit('chart/setDateRangeType', {listType: props.listType, dateRangeType: _val})
        if (_val === 'custom') return; // we don't want to fetch until the dates are set
				store.dispatch('sensor/findInfo', {id: store.state.sensor.id, listType:'group_gateway_sensor',chart:true})
			}

			const setFromDate = (event) => {
        store.commit('chart/setDate',{listType: props.listType, date: event.target.value, which: 'fromDate'});
        if (toDate.value.length > 0) {
          store.dispatch('sensor/findInfo', {id: store.state.sensor.id, listType:'group_gateway_sensor',chart:true});
        }
			}

			function setToDate(event) {
        store.commit('chart/setDate',{listType: props.listType, date: event.target.value, which: 'toDate'});
        if (fromDate.value.length > 0) {
          store.dispatch('sensor/findInfo', {id: store.state.sensor.id, listType:'group_gateway_sensor',chart:true});
        }
			}

			onMounted(() => {
        store.commit('chart/setDateRangeType', {listType: props.listType, dateRangeType: '30r'})
      });

			return {
				updateDateRange,
				setFromDate,
				setToDate,
				daterange: computed(() => store.state.chart.filters.dateRangeType[props.listType]),
				fromDate,
				toDate,
			}
		}
	}
</script>

<style scoped>
.accordion {
  --bs-accordion-color: var(--color-txt);
}

input[type='date'] {
  pointer-events: none;
}

input[type='date']::-webkit-calendar-picker-indicator {
  pointer-events: auto;
  color-scheme: var(--scheme);
}
</style>
