<template>
  <tr class="text-nowrap" v-bind:class="statusClass(status)">
    <td><input class="form-control text-center" type="number" :min="1" :max="255" :value="sensor_address" @change="updateStore('sensor_address', array_idx, $event.target)"/></td>
    <td><input class="form-control text-center" type="number" :min="0" :max="999" :value="sensor_register" @change="updateStore('sensor_register', array_idx, $event.target)"/></td>
    <td><input class="form-control text-center" type="number" :min="1" :max="30" :value="length" @change="updateStore('length', array_idx, $event.target)"/></td>
    <td><input class="form-control text-center" type="number" :max="2" :min="1" :value="power_line" @change="updateStore('power_line', array_idx, $event.target)"/></td>
    <td><input class="form-control text-center" type="number" :max="4" :min="1" :value="application_type" @change="change(array_idx, $event.target)"/></td>
    <td>{{ application_type_description }} </td>
    <td><button class="form-control" @click="removeGCR(array_idx)">X</button></td>
  </tr>
</template>

<script>
  import { useStore } from "vuex";

  export default {
    props: {
      sensor_address: Number,
      idx: Number,
      sensor_register: String,
      length: Number,
      sensor_type_name: String, 
      power_line :Number, 
      application_type:String, 
      application_type_description: String,
      array_idx: Number,
      status: String,
    },
    setup(props) {
      const store = useStore();
      const showModal = () => {
        console.log('click modal')
        store.commit("apgList/showModal", {});
      };

      const clamp = (target) => {
        let min = target.getAttribute('min');
        let max = target.getAttribute('max');
        return Math.min(Math.max(min, target.value), max);
      };

      const change = function (id, target) {
        let value = clamp(target);
        store.dispatch("gateway/changeFunctionGatewayReadingControl", {
          array_idx: props.array_idx,
          value:value,
          id:id
        });
      };

      const updateStore = function (item, id, target) {
        let value = clamp(target);
        store.dispatch("gateway/updateStoreGatewayReadingControl", {
          item:item,
          array_idx:
          props.array_idx,
          value:value,
          id:id
        });
      };

      const removeGCR = function (id) {
        store.dispatch("gateway/removeGRC", {id:id});
      };

      const statusClass = (status) => {
        if (status === 'existing') return;
        return status;
      };

      return {
        showModal,
        change,
        updateStore,
        removeGCR,
        statusClass
      };
    },
  };
</script>

<style scoped>
  li {
    cursor: pointer;
  }
  li:hover {
    background-color: antiquewhite;
  }

  tr.new td {
    border: 3px solid #41b883;
  }

  tr.updated td {
    border: 3px solid orange;
  }

  tr.deleted td {
    position: relative;
  }

  tr.deleted td:before {
    content: " ";
    position: absolute;
    top: 49%;
    left: 0;
    width: 100%;
    border-bottom: 3px solid var(--color-txt);
    z-index: 1000;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 var(--color-border);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px var(--color-border);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 var(--color-border);
    }
  }
</style>