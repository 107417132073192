<template>
  <tr class="text-nowrap" :class="setClass(status)" ref="reference">
    <td>
      <text class="form-control text-center">{{sensor_id}}</text>
    </td>
    <td>
      <input type="number" @change="setAlarmRegister" class="form-control text-center" id="alarmReg" :value="alarm_register"/>
    </td>
    <td>
      <apg-dropdown table="alarm_type" 
      :showDefaultValue="true" defaultEmptyValue="0" 
      defaultEmptyValueMessage="Choose alarm type"
      valueField="alarm_type_id" 
      descriptionField="type_pretty_name" 
      :idx="array_idx"
      class="text-center" @change="setAlarmType"
      />
    </td>
    <td>
      <input type="number" step="0.01"
             @change="setAlarmValue"
             class="form-control text-center"
             id="alarmValue" :value="alarm_value"
      />

      <input v-if="alarm_type == 4 || alarm_type == 5"
             type="number" @change="setHysteresisValue"
             class="form-control text-center" id="hysteresisValue"
             :value="hysteresis"
      />
    </td>
    <td>
      <Multiselect style="background: transparent" class="form-control w-auto"
          :options="options[array_idx].toWhom"
          v-model="value[array_idx].selected_notify"
          label="displayname"
          track-by="displayname"
          placeholder=""
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :searchable="false"
          open-direction="below"
          :show-labels="false"
          @close="setNotifications" @open="getNotifications"
      >
        <template v-slot:selection="{values}">
          <span class="multiselect__single">
            {{ calcSelected(values) }} users selected
          </span>
        </template>
      </Multiselect>
    </td>
    <td>
      <apg-dropdown table="alarm_action_type"
      :showDefaultValue="false"
      valueField="alarm_action_type_id"
      descriptionField="type_pretty_name"
      :idx="array_idx"
      />
    </td>
    <td>
      <button class="form-control" @click="removeAlarm(array_idx)">X</button>
    </td>
  </tr>
</template>

<script>
  import { useStore } from "vuex";
  import ApgDropdown from '@/components/shared/lists/apg-dropdown.vue'
  import {computed} from "vue";
  import Multiselect from "vue-multiselect";
  export default {
    components: {
      ApgDropdown,
      Multiselect
  },
    props: {
      sensor_id: String,
      alarm_type: String,
      alarm_value: String,
      alarm_register: String,
      hysteresis: String,
      status: String,
      array_idx: Number,
      idx: Number,
    },
    setup(props) {
      const store = useStore();
      
      const removeAlarm = function (id) {
        // remove the alarm from the db
        store.dispatch("alarm/removeAlarm", {id:id});
        // update the overview
        store.dispatch('gateway/findInfoOverview', {id: store.state.apgList.ids.gateway.gateway});
      }

      const setAlarmRegister = function(e) {
        store.commit('apgList/setSingle', {
          listType:'alarm_set',
          index: props.array_idx,
          name : 'alarm_register',
          data: e.target.value
        })
        updateStatus('updated');
      }
      const setAlarmValue = function(e) {
        store.commit('apgList/setSingle', {
          listType:'alarm_set',
          index: props.array_idx,
          name : 'alarm_set_point',
          data: e.target.value
        })
        updateStatus('updated');
      }

      const setAlarmType = (e) => {
        store.commit('apgList/setListIndexProp', {
          listType: 'alarm_set',
          idx: props.array_idx,
          prop: 'alarm_type_id',
          data: e.target.value
        });
        updateStatus('updated');
      }

      const setHysteresisValue = (e) => {
        store.commit('apgList/setSingle', {
          listType: 'alarm_set',
          index: props.array_idx,
          name: 'alarm_set_point_2',
          data: e.target.value
        });
        updateStatus('updated');
      };

      let selectedNotifs = [];
      const getNotifications = () => {
        selectedNotifs = store.state.apgList.List.alarm_set[props.array_idx].selected_notify;
      };

      const setNotifications = (e) => {
        // if the arrays are different, update the status
        if (JSON.stringify(selectedNotifs) !== JSON.stringify(e)) {
          store.commit('apgList/setList', {
            ListType: 'alarm_set',
            name: 'selected_notify',
            data: [e]
          });
          updateStatus('updated');
        }
      };

      const updateStatus = (status) => {
        if (store.state.apgList.List?.alarm_set?.[props.array_idx]?.status === 'new') {
          return;
        }

        store.commit('apgList/setSingle', {
          listType: 'alarm_set',
          index: props.array_idx,
          name: 'status',
          data: status
        });

        // save the alarms
        store.dispatch('alarm/SaveAlarms');
      };

      const calcSelected = (values) => {
        return values?.length || 0;
      }

      const setClass = (status) => {
        if (status === 'existing') return;
        return status == 'deleted' ? 'strikeout' : status;
      }

      return {
        removeAlarm,
        setAlarmRegister,
        setAlarmValue,
        setAlarmType,
        setHysteresisValue,
        getNotifications,
        setNotifications,
        calcSelected,
        setClass,
        selectedValues: [
          { "values": []},
          { "values": []},
        ],
      };
    },
    data() {
      // Notifications multiselect stuff
      return {
        value: computed(() => {
          let store = useStore();
          return store.state.apgList.List.alarm_set;
        }),
        options: computed(() => {
          let store = useStore();
          return store.state.apgList.List.alarm_set;
        })
      }
    }
  };
</script>

<style scoped>
  li {
    cursor: pointer;
  }
  li:hover {
    background-color: antiquewhite;
  }

  tr.new td {
    border: 3px solid #41b883;
  }

  tr.updated td {
    border: 3px solid orange;
  }

  tr.strikeout td {
      position: relative;
  }

  tr.strikeout td:before {
      content: " ";
      position: absolute;
      top: 49%;
      left: 0;
      width: 100%;
      border-bottom: 3px solid var(--color-txt);
      z-index: 1000;
  }
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>