import axios from 'axios'
import store from '../store'
import { useToast } from 'vue-toastification';
import socketInstance from "@/services/Socket";
const toast = useToast();

export const sensor = {
  namespaced: true,

  state: {
    url_part: 'sensors',
    url_sensor: 'sensors/info/',
    url_sensors_for_gateway_me: 'mySensors/gateway/',
    url_sensor_attribute: 'sensor/attribute',
    url_sensor_holding_registers: 'sensors/holdingRegisters/',
    url_sensor_update_HR: 'sensors/updateHoldingRegisters',
    url_sensor_get_HR: 'sensors/getHoldingRegisters/',
    url_update_register_to_read : 'sensors/updateRegToRead/',
    url_sensors_overview : 'sensors/overview/',
    url_sensor_ai: 'sensors/ai/',
  },
  mutations: {
    setCurrentId(state, payload) {
      console.log('setting state id to ',payload)
      console.log('state id is ',state)
      state.id = payload.id
    },
  },
  actions: {
    async findAllForGatewayMe ({rootState, state, commit}, payload) { 
      let id = payload.id
      console.log('find all sensors for gateway ' + id)
      let url = rootState.settings.api_base + state.url_sensors_for_gateway_me + id;

      try {
        let rs = await axios.get(url)
        console.log('found sensors list for gateway ' + id, rs.data)
        if (typeof payload !== 'undefined') {
          commit('MyView/addChildrenToChild', {
            listType:payload.listType,
            idx:payload.idx,
            idx2: payload.idx2,
            data: rs.data},
            {root: true}
          )
        }
      } catch (err) { 
        console.error('Failed to load my sensor info for gateway.  Error was ' + id, {err})
      }
    },

    async findInfoOverview({ rootState, state, commit }, payload) {
      let url = rootState.settings.api_base + state.url_sensors_overview + payload.id;
      try {
        let rs = await axios.get(url)
        commit('apgList/setList', { listType: "sensor_overview", data: rs.data.Attributes},{root: true});
        commit('apgList/setList', { listType: "gateway_overview", data: rs.data.GatewayAttributes},{root: true});
      }catch (err) {
        console.error('Failed to load my gateway overview.  Error was ')
        console.error({ err })
      }
    },

    async insertHoldingRegisters ({rootState, state}, payload) {
      console.log('updating holding register for ' + payload.sensorId + ' for holding_register_id ' + payload.holding_registers_id + ' having value = ' + payload.value)
      let url = rootState.settings.api_base + state.url_sensor_holding_registers + payload.sensorId + '/' + payload.holding_registers_id + '/' + payload.value;

      try {
        let rs = await axios.get(url);
        if( typeof rs === 'undefined' ) {
          console.log('Failed to insert holding register value for sensor' + payload.sensorId + ' rs was undefined.')
          return
        } 
        console.log('updated holding register for ' + payload.sensorId, rs.data)
        toast.success('Holding register updated successfully!');
      } catch (err) {
        toast.error('Failed to update holding register.');
        console.error('Failed to load sensor info for: ' + state.id + ' ERROR: ',{err})
      }
    },

    async updateSensorNewHoldingRegisters({rootState, state, dispatch}) {
      const HRs = store.state.apgList.List.sensor_holdingRegisters
      const sensorId = store.state.apgList.List.sensor_info.sensor_id
      let errSaving = false;
      for (let HR of HRs) {
        if (HR.origin == 'Not saved') {
          HR.sensor_id = sensorId;
          console.log('Holding registers changed', HR)
          let url = rootState.settings.api_base + state.url_sensor_update_HR
          console.log('update holding register url is ', url)
          try {
            let rs = await axios.post(url, HR)
            console.log(rs)
          } catch (err) {
            console.error('Failed to load my sensor info for: ' + state.id + ' ERROR: ', {err})
            errSaving = true;
          }
        }
      }
      if (!errSaving) {
        const gateway_id = store.state.apgList.ids.gateway.gateway
        dispatch('findSensorHoldingRegisters', {sensor_id: sensorId})
        dispatch("gateway/findQueuedMessages", {id: gateway_id}, {root: true});
      }
    },

    async updateRegisterToRead ({rootState, state, commit}, payload) {
      let url = rootState.settings.api_base + state.url_update_register_to_read + payload.sensor_id + '/' + payload.value
      console.log(url)
      try {
        let rs = await axios.patch(url)
        if( typeof rs === 'undefined' ) {
          console.log('Failed to update register to read')
        }
        commit('apgList/setID', {listType: 'register', idType: 'register_id', _id: payload.value},{root: true});
      } catch (err) { 
        console.error('Failed to update register to read',{err})
      }
    },   

    async findSensorHoldingRegisters ({rootState, state, commit}, payload) {
      let url = rootState.settings.api_base + state.url_sensor_get_HR + payload.sensor_id;
      try {
        let rs = await axios.get(url);

        if (!rs) {
          console.log('Failed to find sensor info for sensor ' + state.id + ' rs was undefined.');
          return
        } 
        console.log('found sensor info for sensor ' + state.id, rs.data);
        if (payload && rs.data) {
          commit('apgList/setList', {listType: 'sensor_holdingRegisters', data:rs.data.HoldingRegisters},{root: true});
        }
      } catch (err) { 
        console.error('Failed to load my sensor info for: ' + state.id + ' ERROR: ', {err});
      }
    },
    
    async findInfo ({rootState, state, commit, rootGetters, dispatch}, payload) {
      commit('settings/setIsLoading', true, {root: true});
      commit('setCurrentId', payload)
      console.log('finding sensor info for sensor ' + state.id)
      if (!state.id) state.id = rootState.apgList.ids.sensor.sensor_id;
      let url = rootState.settings.api_base + state.url_sensor + state.id
      let date_url = rootGetters['chart/getDateRangeUrl']({listType: payload.listType})
      let chart_type = rootGetters['chart/getChartType']({listType: payload.listType});

      url = url + '/' + date_url;

      console.log('url with date range added is: ',url);

      try {
        let rs = await axios.get(url, {
          params: {chartType: chart_type},
        });

        console.log('rs is ', rs)
        if( typeof rs === 'undefined' ) {
          console.log('Failed to find sensor info for sensor ' + state.id + ' rs was undefined.')
          return
        } 
        console.log('found sensor info for sensor ' + state.id, rs.data)
        if (typeof payload !== 'undefined' && rs.data) {
          commit('apgList/setList', {listType: 'sensor_info',data:rs.data.Info[0]},{root: true})
          commit('apgList/setExportData',{listType: 'sensor_readings',data:rs.data.ExportData},{root: true})
          commit('apgList/setList', {listType: 'sensor_attribute',data:rs.data.Attributes},{root: true})

          // loop through attributes and if one is a dropdown set that value into apgList.
          let attr = rs.data.Attributes
          console.log('Loading sensor attributes and setting dropdown values',attr)
          for(let i= 0; i < attr.length; i++) {
            if(attr[i].attribute_type == 'dropdown') {
              let table = attr[i].attribute_key.toLowerCase().replace(' ', '_')
              console.log('Found a dropdown so setting value for ' + payload.listType + ' = ' + attr[i].attribute_value)
              commit('apgList/setDropdownValue',{table,data:attr[i].attribute_value,idx:0,listType:'sensor_attribute'},{root:true})
            }
          }

          commit('apgList/setList', {listType: 'sensor_holdingRegisters',data:rs.data.HoldingRegisters},{root: true})
          commit('apgList/setList', {listType: 'register_attribute',data:rs.data.AttributesReg},{root: true})
          commit('apgList/setList', { listType:"alarm_history", data: rs.data.AlarmHistory }, {root: true})
          commit('apgList/setList', { listType:"alarm_count_active", data: rs.data.AlarmCountActive }, {root: true})

          let gateway_id = rs.data.Info[0]['gateway_id'];

          // update gateway data with new id
          commit("apgList/setID", {listType:"gateway", idType: 'gateway',_id: gateway_id}, {root: true});
          dispatch("gateway/findGatewayReadingControl", { id: gateway_id }, {root: true});
          dispatch("gateway/findQueuedMessages", { id: gateway_id }, {root: true});
          dispatch("gateway/findSentMessages", { id: gateway_id }, {root: true});
          dispatch("gateway/findAlarmGateway", { id: gateway_id }, {root: true});
          dispatch('gateway/findOne', {listType: 'gateway_attribute', id: gateway_id}, {root: true});
          dispatch('gateway/findGatewayTimings', {id: gateway_id}, {root: true});
          dispatch('gateway/findGatewayGPSLocation', {id: gateway_id}, {root: true});

          // get the ai predictions for this sensor
          let address = rs.data.Attributes.find(a => a.attribute_name === 'addressToRead')?.attribute_value;
          let aiEnabled = rs.data.AttributesReg?.find(a => a.attribute_name === 'predictions_enabled')?.attribute_value;
          if (address && aiEnabled === 'true') {
            dispatch('sensor/getAiPrediction', {
              listType: 'ai_predictions',
              id: state.id,
              address: address,
              ai: aiEnabled,
              chartType: chart_type,
            }, {root: true});
          }

          if (payload.chart) {
            commit('chart/setRegisterToRead', address, {root: true});
            commit('chart/setChartData', {
                ...payload,
                data: rs.data.Readings,
                valueField: 'sensor_data_value',
                valueField2: 'sensor_data_value2',
                labelField: 'receive_datetime',
                dataLabel: 'Sensor Readings',
                unresponsive_data: rs.data.UnresponsiveData
              }, {root: true}
            )
          }
          // set the socket listener for this sensor and register
          socketInstance.sendData('watching_sensor', {sensor_id: state.id, register: address});
        }
      } catch (err) { 
        console.error('Failed to load my sensor info for: ' + state.id + ' ERROR: ',{err})
      }
      commit('settings/setIsLoading', false, {root: true});
    },

    async getAiPrediction({rootState, state, commit}, payload) {
      let url = rootState.settings.api_base + state.url_sensor_ai + payload.id +'/'+ payload.address;
      console.log('ai url: ', url);

      let rs = await axios.get(url, {
        params: {chartType: payload.chartType},
      });
      console.log('ai prediction response', rs);

      if (rs?.status === 200) {
        commit('chart/setChartPredictionData', {
          listType: payload.listType,
          prediction: rs.data.prediction,
          prediction2: rs.data.prediction2,
          timestamps: rs.data.timestamps,
        }, {root: true});
      } else if (rs?.status === 202) {
        toast.warning(rs.data, {timeout: 4000});
      } else {
        toast.error('Failed to get AI prediction data');
        console.error('APG AI error:', rs.data.error);
      }
    },

    async getRegisters({rootState, state, commit}, payload) {
      let url = rootState.settings.api_base + state.url_sensor + payload.sensor_id + '/registers';
      console.log('get registers url: ', url);

      let rs = await axios.get(url);
      console.log('get registers response', rs);

      if (rs?.status === 200) {
        commit('apgList/setList', {listType: 'sensor_registers', data: rs.data}, {root: true});
      } else {
        toast.error('Failed to get sensor registers');
        console.error('getSensorRegisters error:', rs?.data?.error);
      }
    },

    async readAllHoldingRegisters ({rootState, state}, payload) {
      let sensorId = payload.sensorId;
      let url = rootState.settings.api_base + state.url_sensor_holding_registers + 'readAll';
      console.log('reading all holding registers for sensor ' + sensorId);
      console.log('url is ', url);

      let rs = await axios.post(url, {
        sensorId: sensorId,
        powerLine: payload.powerLine,
      });
      console.log('read all holding registers response', rs);

      if (rs?.status === 200) {
        toast.success('Read All Holding Registers message queued successfully!');
        // refresh sensor info
        await store.dispatch('sensor/findInfo', {
          id: sensorId,
          listType: 'group_gateway_sensor',
          chart: true
        });
        await store.dispatch('sensor/findInfoOverview', {id: sensorId});
        await store.dispatch('sensor/getRegisters', {sensor_id: sensorId});
      } else {
        toast.error('Failed to queue read all holding registers message. Please try again.');
        console.error('readAllHoldingRegisters error:', rs?.data?.error);
      }
    }
  },
  getters: {}
}