<template>
  <div class="row">
    <div class="col-md-5"/>
  </div>
  <Tree
    :nodes="data"
    @nodeClick="nodeClicked"
    ref="MyTreeViewRef"
    :rowHoverBackground="'var(--color-sec)'"
  />
</template>

<script>
import { useStore } from 'vuex'
import { computed, ref, onMounted, onUpdated, nextTick } from 'vue'
import Tree from 'vue3-tree'
import 'vue3-tree/dist/style.css'

export default {
  name: 'Mv-tree-list',
  components: {
    Tree,
  },
  props: {
    source: String,
  },
  setup(props) {
    const store = useStore()

    // const data = [{id: 0,label:"no data found"},{id:2,label:'some other data'},]

    let MyTreeViewRef = ref(null)

    onMounted(() => {
      console.log('ref is ', MyTreeViewRef.value.$el)
      store.commit('MyView/setTreeRef', MyTreeViewRef.value.$el)
    })

    onUpdated(() => {
      // clear displayed data when the page loads
      store.commit('apgList/clearLists', {});
      store.commit('chart/clearData', {});

      console.log('store state has changed')
      addBadgesToGroup()
    })

    function createBadgeAlarm(_count, _class) {
      let badge = document.createElement('div')
      //Sorry for the icon below... this should be a bell... but the <font-awesome... doesn't work...
      badge.innerHTML = '<svg class="svg-inline--fa fa-bell fa-w-05 blink_me" color="#dc3545" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bell" role="img" viewBox="0 0 448 512"><path class="" fill="currentColor" d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"></path></svg>'
      badge.className = ' ' + _class /*'badge rounded-pill ' + _class*/
      badge.style.marginLeft = '10px'
      return badge
    }

    function createBadgeMessage(_count, _class) {
      let message = document.createElement('div')
      //Sorry for the icon below... this should be an envelope... but the <font-awesome... doesn't work...
      message.innerHTML = '<svg class="svg-inline--fa fa-envelope fa-w-16"0 color="#FC4E12" aria-hidden="true" focusable="true" data-prefix="fas" data-icon="envelope" role="img" viewBox="0 0 512 512"><path class="" fill="currentColor" d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path></svg>'
      message.className = '' + _class /*'badge rounded-pill ' + _class*/
      message.style.marginLeft = '10px'
      return message
    }

    async function addBadgesToGroup() {
      let a = store.state.MyView.data[props.source]
      for (let i = 0; i < a.length; i++) {
        await nextTick()
        let divs = MyTreeViewRef.value.$el.querySelectorAll('div.tree-row-item')
        divs[i].classList.add('group_' + a[i].id)
        
        if (a[i].alarm_count && a[i].alarm_count != 0) {
          let badge = createBadgeAlarm(a[i].alarm_count, '')
          divs[i].append(badge)
        }

        if (a[i].message_queue_count && a[i].message_queue_count != 0) {
          let message = createBadgeMessage(a[i].alarm_count, '')
          divs[i].append(message)
        }
      }
    }

    function addBadgesToGateway(node) {
      if (node.gateway_count && node.gateway_count > 0) {
        let gateways = MyTreeViewRef.value.$el.querySelectorAll('div > ul > li.tree-row');
        let thisGateway = gateways[node.idx]
        let a = store.state.MyView.data[props.source][node.idx].nodes
        for (let i = 0; i < a.length; i++) {
          let divs = thisGateway.querySelectorAll('div.tree-row-item')
          console.log('divs is', divs[i + 1])

          divs[i + 1].classList.add('gateway_' + node.id + '_' + a[i].id)

          if (a[i].alarm_count_active && a[i].alarm_count_active != 0) {
            let badge = createBadgeAlarm(a[i].alarm_count_active, '')
            divs[i + 1].append(badge)
          }

          if (a[i].message_queue_count && a[i].message_queue_count != 0) {
            let message = createBadgeMessage(a[i].alarm_count, '')
            divs[i + 1].append(message)
          }
        }
      }
    }

    async function addClassToSensor(node) {
      await nextTick()
      let sensorList = store.state.MyView.data[props.source][node.groupIndex].nodes[node.gatewayIndex].nodes;

      let groupItem = store.state.MyView.data[props.source][node.idx];  // groups
      let gatewaySelector = '.gateway_' + groupItem.id + '_' + node.id;
      console.log('gateway selector is ' + gatewaySelector)

      let gatewayElement = MyTreeViewRef.value.$el.querySelector(gatewaySelector)
      let parentElement = gatewayElement.closest('li');
      let sensors = parentElement.querySelectorAll('div.tree-row-item');
      console.log('sensors are', sensorList);

      for (let i = 1; i < sensors.length; i++) {
        console.log('sensor found was ',sensors[i]);
        sensors[i].classList.add('sensor_' + groupItem.id + '_' + node.id + '_' + sensorList[i-1].id);
      }
    }

    async function setActive(_node) {
      await nextTick()
      console.log('setActive : node was ', _node)
      let activeOld = MyTreeViewRef.value.$el.querySelector('.activeSelect')
      console.log('current active ', activeOld)

      if (activeOld != null) {
        activeOld.classList.remove('activeSelect')
      }

      if (_node.level_type == 'group') {
        MyTreeViewRef.value.$el.querySelector('.group_' + _node.id).classList.add('activeSelect');
      } else if (_node.level_type == 'group_gateway') {
        let groupItem = store.state.MyView.data[props.source][_node.groupIndex]
        let gateway = '.gateway_' + groupItem.id + '_' + _node.id
        console.log('setting active gateway', gateway)
        MyTreeViewRef.value.$el.querySelector(gateway).classList.add('activeSelect');
      } else {
        let groupItem = store.state.MyView.data[props.source][_node.groupIndex];
        let gatewayItem = store.state.MyView.data[props.source][_node.groupIndex].nodes[_node.gatewayIndex];
        let sensor = '.sensor_' + groupItem.id + '_' + gatewayItem.id + '_' + _node.id;
        MyTreeViewRef.value.$el.querySelector(sensor).classList.add('activeSelect');
      }
    }

    const nodeClicked = function (node) {
      console.log('clicked node ', node)
      setActive(node)

      // group clicked
      if (node.level_type == 'group' && node.expanded) {
        store.dispatch('gateway/findAllForGroupMe', {group_id: node.id,idx: node.idx,listType: props.source})
          .then(() => addBadgesToGateway(node));
        store.commit('apgList/setID',{ listType: 'group', idType: 'group_id', _id: node.id },{ root: true })
      }

      // gateway clicked
      if (node.level_type == 'group_gateway' && node.expanded) {
        console.log('found sensors for gateway ' + node.id)
        store.commit('apgList/clearLists', {});
        store.commit('chart/clearData', {});
        store.commit('alarm/setCurrentGatewayID', node.id)
        store.commit('chart/clearData',{ listType: props.listType },{ root: true })
        store.dispatch('sensor/findAllForGatewayMe', {id: node.id,idx: node.idx,idx2: node.idx2,listType: props.source,})
          .then(async () => await addClassToSensor(node));
        store.dispatch('gateway/findGatewayReadingControl', { id: node.id })
        store.dispatch('gateway/findQueuedMessages', { id: node.id })
        store.dispatch('gateway/findSentMessages', { id: node.id })
        store.dispatch('gateway/findAlarmGateway', { id: node.id })
        store.dispatch('gateway/findGatewayGPSLocation', {id: node.id })
        store.dispatch('gateway/findInfoOverview', {id: node.id})
        store.dispatch('gateway/findGatewayTimings', {id: node.id});
      }

      // sensor clicked
      if (node.level_type == 'group_gateway_sensor') {
        store.commit(
          'chart/clearData',
          { listType: props.listType },
          { root: true }
        )
        console.log('Let display information for that sensor ' + node.id)
        store.commit(
          'apgList/setID',
          { listType: 'sensor', idType: 'sensor_id', _id: node.id },
          { root: true }
        )
        store.dispatch('sensor/findInfo', {
            id: node.id,
            listType: props.source,
            chart: true,
          })
        store.commit('alarm/setCurrentSensor', { id: node.id })
        store.dispatch('sensor/findInfoOverview', {id: node.id})
        store.dispatch('sensor/getRegisters', {sensor_id: node.id});
      } else {
        store.commit('apgList/clearLists', {});
        store.commit('chart/clearData', {});
      }
    }

    return {
      data: computed(() => {
        return store.state.MyView.data[props.source]
      }),
      nodeClicked,
      MyTreeViewRef,
    }
  },
}
</script>
